import { useCallback, useState } from "react";
import {
  Product,
  ProductAttribute,
  ProductImage,
  ProductVariation,
  ProductVariationAttribute,
} from "../contexts/products";
import { renderPrice } from "../utils/price";
import { Button } from "./Button";

import magnifying from "./assets/magnifying.svg";
import { CartItem, CartItemType, useHeyFlowLeadContext } from "../contexts/heyFlowLead";
import { useLocation, useNavigate } from "react-router-dom";
import { WordPressImage } from "./WordPressImage";
import { usePopupsContext } from "../contexts/popups";
import { Headline } from "./Headline";

export interface ProductDetailsProps {
  product: Product;
  productVariation: ProductVariation[];
  preSelectedVariationId?: number | undefined;
  isCartItem?: boolean;
  addItem: (item: CartItem, goBackToPurchase: boolean) => void;
}

function hasVariations(availableVariations: ProductVariation[], selection: Record<number, string>) {
  return availableVariations.some((variation) => {
    return variation.attributes.every((attribute) => {
      return selection[attribute.id] === undefined || selection[attribute.id] === attribute.option;
    });
  });
}

function variationsForSelection(availableVariations: ProductVariation[], selection: Record<number, string>) {
  return availableVariations.filter((variation) => {
    return variation.attributes.every((attribute) => {
      return selection[attribute.id] === undefined || selection[attribute.id] === attribute.option;
    });
  });
}

interface ProductImagesProps {
  onlyFirst: boolean;
  images: ProductImage[];
  setViewImageDetails: (image: ProductImage) => void;
}

const ProductImages = (props: ProductImagesProps) => {
  return (
    <div className="max-w-md mx-auto">
      {props.images.map((image, index) => (
        <div
          key={index}
          className={`inline-block w-1/2 p-4 first:w-full ${props.images.length % 2 === 0 ? "first:lg:w-1/2" : ""} ${
            props.onlyFirst ? "hidden first:inline-block lg:inline-block" : ""
          }`}
        >
          <div
            className="group rounded-full overflow-hidden cursor-pointer relative"
            onClick={() => props.setViewImageDetails(image)}
          >
            <WordPressImage
              src={image.src}
              alt={image.alt}
              showThumbNail={true}
              className="w-full scale-110"
            ></WordPressImage>
            <div className="hidden group-hover:flex absolute top-0 left-0 w-full h-full bg-black opacity-20 justify-center items-center">
              <img src={magnifying} alt="magnifying" className="w-1/2"></img>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export function ProductDetails(props: ProductDetailsProps) {
  const { value, standardProductDefinitions } = useHeyFlowLeadContext();
  const navigate = useNavigate();
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const goBackToPurchase = urlParams.get("back") === "purchase";

  const { setPopupType } = usePopupsContext();
  const selectableAttributes = props.product.attributes.filter((attribute) => attribute.variation);

  const [selectedAttributes, setSelectedAttributes] = useState<Record<number, string>>(() => {
    if (props.preSelectedVariationId !== undefined) {
      const variation = props.productVariation.find((variation) => variation.id === props.preSelectedVariationId);
      if (variation !== undefined) {
        const selection: Record<number, string> = {};
        variation.attributes.forEach((attribute) => {
          selection[attribute.id] = attribute.option;
        });
        return selection;
      }
    }
    return {};
  });

  const setViewImageDetails = useCallback(
    (productImage: ProductImage) => {
      setPopupType({ type: "imageDetails", image: productImage });
    },
    [setPopupType]
  );

  const setPopoverMessage = useCallback(
    (message: string) => {
      setPopupType({ type: "message", message });
    },
    [setPopupType]
  );

  const selectedVariations = variationsForSelection(props.productVariation, selectedAttributes);
  const [uniqueSelectedVariation, variationAttributes] =
    selectedVariations.length === 1
      ? [selectedVariations[0], selectedVariations[0].attributes]
      : selectedVariations.length === 0
        ? [props.product, undefined]
        : [undefined, undefined];

  const dimensions = uniqueSelectedVariation?.dimensions ?? props.product.dimensions;
  const weight = uniqueSelectedVariation?.weight ?? props.product.weight;
  const hasDimensions =
    Number(dimensions.length) !== 0 || Number(dimensions.height) !== 0 || Number(dimensions.width) !== 0;

  const colorAttribute = (
    uniqueSelectedVariation?.attributes as (ProductAttribute | ProductVariationAttribute)[]
  )?.find((attribute) => attribute.name === "Farbe");
  let color = (colorAttribute as ProductVariationAttribute)?.option ?? (colorAttribute as ProductAttribute)?.options[0];
  if (!color) {
    const productColors = props.product.attributes.find((attribute) => attribute.name === "Farbe");
    if (productColors?.variation === false) {
      color = productColors.options[0];
    }
  }

  const details: [string, string | string[]][] = [];
  if (color) {
    //details.push(["Farbe", color]);
  }
  if (
    hasDimensions &&
    !props.product.attributes.find((attribute) => attribute.variation === false && attribute.name === "Maße")
  ) {
    details.push(["Maße", `${dimensions.height} cm x ${dimensions.length} cm x ${dimensions.width} cm`]);
  }
  if (Number(weight) !== 0) {
    details.push(["Gewicht", `${weight} cm`]);
  }
  props.product.attributes.forEach((attribute) => {
    if (attribute.variation === false) {
      if (
        attribute.name === "Verwendung" ||
        attribute.name === "Beschriftung" ||
        attribute.name === "Volumen" ||
        attribute.name === "Maße"
      ) {
        let value: string | string[] = attribute.options[0];
        if (attribute.name === "Volumen" || attribute.name === "Maße")
          value = value
            .split("Größe")
            .map((s, index) => (index > 0 ? `Größe${s}` : s))
            .filter((s, index) => index > 0 || s.trim().length > 0);
        details.push([attribute.name, value]);
      }
    }
  });

  if (!standardProductDefinitions) return null;
  const { placeholderProducts } = standardProductDefinitions;

  const { categories, id } = props.product;
  let type: CartItemType | undefined;
  const foundCategory = (Object.keys(placeholderProducts) as CartItemType[]).find((category) => {
    return id === placeholderProducts[category].productId;
  });

  if (foundCategory !== undefined) {
    type = foundCategory;
  } else {
    if (id === 27448 || id === 24936) {
      type = "cremation";
    } else if (id === 27456) {
      type = "energy";
    } else if (id === 24938) {
      type = "pawPrint";
    } else if (id === 24937) {
      type = "transport";
    } else if (categories?.some((category) => category.id === 818)) {
      type = "urn";
    } else if (categories?.some((category) => category.id === 803)) {
      type = "inscription";
    } else if (categories?.some((category) => category.id === 541)) {
      type = "jewelry";
    } else if (id === 40874) {
      type = "certificate";
    } else if (id === 40875) {
      type = "diamondConsultation";
    }
  }

  let buttonCaption;
  if (props.isCartItem === true) {
    if (
      uniqueSelectedVariation !== undefined &&
      props.preSelectedVariationId !== undefined &&
      uniqueSelectedVariation.id !== props.preSelectedVariationId
    ) {
      buttonCaption = "Produkt ändern";
    } else {
      buttonCaption = "Zurück";
    }
  } else {
    buttonCaption = "Produkt Auswählen";
  }

  return (
    <div className="bg-neutral-100 rounded-xl py-8 md:py-14 px-4 md:px-20 mt-10 text-sm sm:text-base">
      {value?.cart.some((cartItem) => cartItem.productId === props.product.id) && (
        <div
          className={`items-center justify-center text-center px-4 rounded-full text-sm transition-opacity
 bg-green text-white inline-flex py-1 hover:opacity-100 opacity-80 cursor-pointer mb-10`}
        >
          Ausgewählt
        </div>
      )}
      <Headline title={props.product.name} type="sub" className="mb-5" />
      {uniqueSelectedVariation === undefined ? (
        <div
          className="text-base md:text-lg font-medium"
          dangerouslySetInnerHTML={{ __html: props.product.price_html }}
        ></div>
      ) : (
        <div className="text-base md:text-lg font-medium">
          {renderPrice(uniqueSelectedVariation.price, "EUR", true)}
        </div>
      )}
      <div className="flex flex-col lg:flex-row">
        <div className="lg:w-1/2 grow-0 shrink-0 order-1 lg:order-0 lg:self-start lg:top-0">
          <div
            className="mt-8 text-sm lg:text-base mb-8"
            dangerouslySetInnerHTML={{ __html: props.product.description || props.product.short_description }}
          ></div>
          <div className="max-w-sm mb-4 md:mb-1">
            {selectableAttributes.map((attribute) => (
              <div className="mb-5" key={attribute.id}>
                <div className="text-base md:text-lg mb-3"> {attribute.name}</div>
                {attribute.options
                  .filter((option) => hasVariations(props.productVariation, { [attribute.id]: option }))
                  .map((option) => (
                    <div
                      className={`w-full py-2 border rounded-full text-base text-center mb-3 ${
                        selectedAttributes[attribute.id] === option
                          ? "bg-neutral-600 text-white border-neutral-600"
                          : "bg-white border-neutral-500"
                      } ${
                        hasVariations(props.productVariation, { ...selectedAttributes, [attribute.id]: option })
                          ? "cursor-pointer"
                          : "text-neutral-300 border-neutral-300"
                      }`}
                      key={option}
                      onClick={() => {
                        if (hasVariations(props.productVariation, { ...selectedAttributes, [attribute.id]: option })) {
                          setSelectedAttributes({ ...selectedAttributes, [attribute.id]: option });
                        } else {
                          setPopoverMessage("Diese Variante ist leider ausverkauft");
                        }
                      }}
                    >
                      {option}
                    </div>
                  ))}

                <div className="text-center">
                  <span
                    className={`underline text-green ${
                      selectedAttributes[attribute.id] === undefined ? "opacity-0" : "cursor-pointer"
                    }`}
                    onClick={() =>
                      setSelectedAttributes((selected) => {
                        const selectedCopy = { ...selected };
                        delete selectedCopy[attribute.id];
                        return selectedCopy;
                      })
                    }
                  >
                    Auswahl aufheben
                  </span>
                </div>
              </div>
            ))}
          </div>
          <div className="max-w-sm md:py-3 max-md:bottom-0 max-md:fixed z-50 max-md:min-w-full max-md:left-0">
            <div className="max-md:mx-4 bg-neutral-100 py-3">
              <Button
                caption={buttonCaption}
                fullWidth={true}
                large={false}
                inactive={uniqueSelectedVariation === undefined && props.isCartItem !== true}
                primary={true}
                onClick={() => {
                  if (props.isCartItem === true) {
                    if (
                      uniqueSelectedVariation === undefined ||
                      props.preSelectedVariationId === undefined ||
                      uniqueSelectedVariation.id === props.preSelectedVariationId
                    ) {
                      navigate(-1);
                      return;
                    }
                  }

                  if (!uniqueSelectedVariation) {
                    setPopoverMessage("Bitte wähle zuerst eine Variante aus");
                    return;
                  }

                  props.addItem(
                    {
                      description: props.product.short_description || props.product.description,
                      image:
                        props.product.images?.[0]?.src ??
                        "https://animaltree.de/wp-content/uploads/2022/01/Blaetter-Logo_high_res-1024x1024.png",
                      name:
                        type === "cremation"
                          ? `${props.product.name}, Tiergewicht ${
                              (uniqueSelectedVariation.attributes as ProductVariationAttribute[]).find(
                                ({ name }) => name === "Tiergewicht"
                              )?.option ?? ""
                            }`
                          : props.product.name,
                      price: Math.round(Number(uniqueSelectedVariation.price) * 100) / 100,
                      productId: props.product.id,
                      variationId:
                        uniqueSelectedVariation.id !== props.product.id ? uniqueSelectedVariation.id : undefined,
                      variationAttributes,
                      type: type ?? "urn",
                      inscriptionIncluded: props.product.tags?.some((tag) => tag.id === 1216) ?? false,
                    },
                    goBackToPurchase
                  );
                }}
              ></Button>
            </div>
          </div>
        </div>
        <div className="lg:w-1/2 grow-0 shrink-0 px-10 flex flex-wrap content-start order-0 lg:order-1 lg:self-start lg:top-0 lg:sticky">
          <ProductImages
            onlyFirst={true}
            images={props.product.images}
            setViewImageDetails={setViewImageDetails}
          ></ProductImages>
        </div>
      </div>
      {details.length > 0 && <Headline title="Details" type="sub" className="mb-3 mt-3 md:mt-20" />}
      {details.map((detail, index) => (
        <div key={index} className="md:flex mb-5 justify-between">
          <div className="shrink-0 grow-0 w-full md:w-3/12 font-semibold md:font-normal">{detail[0]}</div>

          <div className="shrink-0 grow-0 w-full md:w-8/12">
            {typeof detail[1] === "string" ? detail[1] : detail[1].map((d, index) => <div key={index}>{d}</div>)}
          </div>
        </div>
      ))}
      <div className="lg:hidden">
        <ProductImages
          onlyFirst={false}
          images={props.product.images}
          setViewImageDetails={setViewImageDetails}
        ></ProductImages>
      </div>
    </div>
  );
}
