import { ConfirmationToken, PaymentIntent } from "@stripe/stripe-js";
import { Lead } from "../contexts/heyFlowLead";
import { PurchaseInformation } from "../contexts/purchase";
import { formatDate } from "../utils/date";
import { PaymentStatus } from "../contexts/payment";

import { Spinner } from "./Spinner";
import { PaymentMethodPreview } from "./PaymentMethodPreview";
import { COUNTRIES } from "./CountrySelect";
import { Link } from "react-router-dom";
import { Button } from "./Button";

interface Props {
  paymentStatus: PaymentStatus | undefined;
  lead: Lead;
  heyflowId: string;
  purchaseInformation: PurchaseInformation;
  paymentIntent: PaymentIntent | undefined;
  paymentMethodPreview: ConfirmationToken.PaymentMethodPreview | undefined;
  paymentMethodPreviewIsLoading: boolean;
}

export function StripeComplete({
  lead,
  heyflowId,
  purchaseInformation,
  paymentIntent,
  paymentStatus,
  paymentMethodPreview,
  paymentMethodPreviewIsLoading,
}: Props) {
  if (!paymentIntent) return null;

  const country = COUNTRIES.find((country) => country.value === purchaseInformation.invoiceAddressCountry)!.text;

  return (
    <div>
      {paymentStatus === "success" && (
        <>
          <div className="lg:pt-6">
            Dein Auftrag für die Bestattung von {purchaseInformation.animalName} ist gut bei uns angekommen. Dein
            persönlicher Ansprechpartner wird sich gleich mit dir in Verbindung setzen, um alle weiteren Details zu
            besprechen.
          </div>
          <div className="mt-7">
            Auftragsnummer: {`#${lead.offerId}`}
            <br />
            Datum: {formatDate(paymentIntent.created * 1000)}
            <br />
            <span className="flex gap-3 items-center">
              Bezahlmethode:{" "}
              {paymentMethodPreviewIsLoading || paymentMethodPreview === undefined ? (
                <Spinner size="small" />
              ) : (
                <PaymentMethodPreview paymentMethodPreview={paymentMethodPreview} />
              )}
            </span>
            <br />
          </div>
          <div className="mt-4 mb-8">
            Kundeninformationen:
            <div className="text-sm">
              {`${purchaseInformation.invoiceAddressFirstName} ${purchaseInformation.invoiceAddressLastName}`}
              <br />
              {purchaseInformation.invoiceAddressStreet}
              <br />
              {purchaseInformation.invoiceAddressZipCode} {purchaseInformation.invoiceAddressCity}
              <br />
              {country}
            </div>
          </div>
          <Link to={`https://animaltree.de`}>
            <Button caption="Zurück zu Animal Tree" fullWidth={true} primary={true} large={true}></Button>
          </Link>
        </>
      )}
      {paymentStatus === "pending" && (
        <div className="flex items-center gap-4 pt-6">
          Deine Zahlung wird gerade bearbeitet. Bitte warte einen Moment. <Spinner size="small" />
        </div>
      )}
      {paymentStatus === "cancelled" && (
        <div className="pt-6">
          <div className="mb-4">Deine Zahlung wurde abgrebochen.</div>
          <Link to={`/${heyflowId}/purchase`}>
            <Button caption="Zurück zum Formular" fullWidth={true} primary={true} large={true}></Button>
          </Link>
        </div>
      )}
      {paymentStatus === "error" && (
        <div className="pt-6">
          <div className="mb-4">Es ist ein Fehler mit deiner Zahlung aufgetreten.</div>
          <Link to={`/${heyflowId}/purchase`}>
            <Button caption="Zurück zum Formular" fullWidth={true} primary={true} large={true}></Button>
          </Link>
        </div>
      )}
    </div>
  );
}
