import { useCallback, useEffect, useRef, useState } from "react";
import { createContextAndProvider } from ".";
import { useHeyFlowLeadContext } from "./heyFlowLead";
import { COUNTRIES, Country, DEFAULT_COUNTRY } from "../components/CountrySelect";
import { CountryCode, DEFAULT_COUNTRY_CODE } from "../components/PhoneInput";
import {
  validateAnimalReturnType,
  validateAnimalStage,
  validateAnimalType,
  validateAnyString,
  validateBoolean,
  validateCountry,
  validateCountryCode,
  validateEmail,
  validatePhoneNumber,
  validateString,
} from "../utils/formValidators";
import { useDebouncedValue } from "../hooks/useDebounceValue";
import {
  PlacePrediction,
  fetchPlaceAutocomplete,
  fetchPlaceDetails,
  fetchPurchaseForm,
  storePurchaseForm,
} from "../utils/api";
import { useAsyncValue } from "../hooks/useAsyncValue";
import { useRegularUpdate } from "../hooks/useRegularUpdate";

export type AnimalType = "cat" | "dog" | "small";
export type AnimalStage = "dead" | "soon" | "forehand";
export type AnimalReturnType = "dhl" | "pickup";

type Argument<F> = F extends (arg: infer A) => unknown ? A : never;

export type FormKeys = keyof typeof purchaseValidators;

export type PurchaseInformation = {
  [K in FormKeys]: Argument<(typeof purchaseValidators)[K]>;
};

export type ValidPurchaseInformation = {
  [K in FormKeys]: ReturnType<(typeof purchaseValidators)[K]>;
};

export type PurchaseFormErrors = Partial<Record<keyof PurchaseInformation, string>>;
export type DirtyStatus = Partial<Record<keyof PurchaseInformation, boolean>>;

const purchaseValidators = {
  // contact
  email: validateEmail,

  // invoice address
  invoiceAddressCountry: validateCountry,
  invoiceAddressFirstName: validateString.bind(null, "Vorname"),
  invoiceAddressLastName: validateString.bind(null, "Nachname"),
  invoiceAddressCompany: validateAnyString,
  invoiceAddressStreet: validateString.bind(null, "Straße"),
  invoiceAddressZipCode: validateString.bind(null, "Postleitzahl"),
  invoiceAddressCity: validateString.bind(null, "Stadt"),
  invoiceAddressPhoneCountryCode: validateCountryCode,
  invoiceAddressPhoneNumber: validatePhoneNumber.bind(null, false),

  // pickup address
  hasExtraPickupAddress: validateBoolean,
  pickupAddressCountry: validateCountry,
  pickupAddressPracticeName: validateString.bind(null, "Arztpraxis"),
  pickupAddressStreet: validateString.bind(null, "Straße"),
  pickupAddressZipCode: validateString.bind(null, "Postleitzahl"),
  pickupAddressCity: validateString.bind(null, "Stadt"),
  pickupAddressPhoneCountryCode: validateCountryCode,
  pickupAddressPhoneNumber: validatePhoneNumber.bind(null, true),

  // pet
  animalType: validateAnimalType,
  animalName: validateString.bind(null, "Tiername"),
  animalWeight: validateString.bind(null, "Tiergewicht"),
  animalStage: validateAnimalStage,

  // urn
  inscription: validateAnyString,

  // return
  returnType: validateAnimalReturnType,

  // return address
  hasExtraReturnAddress: validateBoolean,
  returnAddressCountry: validateCountry,
  returnAddressFirstName: validateString.bind(null, "Vorname"),
  returnAddressLastName: validateString.bind(null, "Nachname"),
  returnAddressCompany: validateAnyString,
  returnAddressStreet: validateString.bind(null, "Straße"),
  returnAddressZipCode: validateString.bind(null, "Postleitzahl"),
  returnAddressCity: validateString.bind(null, "Stadt"),

  // remarks
  remarks: validateAnyString,
};

function countryCodeForCountry(country: Country): CountryCode {
  switch (country) {
    case "AT":
      return "+43";
    case "CH":
      return "+41";
    case "DE":
      return "+49";
    case "NL":
      return "+31";
    case "BE":
      return "+32";
    case "PL":
      return "+48";
  }
}

async function storePurchaseInformationForHeyFlowId(heyflowId: string, purchaseInformation: PurchaseInformation) {
  if (purchaseInformation === undefined) {
    return;
  }

  await storePurchaseForm(heyflowId, purchaseInformation);
}

const usePurchase = () => {
  const [purchaseInformation, setPurchaseInformation] = useState<PurchaseInformation | undefined>(undefined);
  const [errors, setErrors] = useState<PurchaseFormErrors>({});
  const dirty = useRef<DirtyStatus>({});

  const hasDatabaseEntry = useRef<boolean | undefined>(undefined);
  const hasBeenUserEdited = useRef<boolean>(false);

  const debouncedInvoiceStreet = useDebouncedValue(purchaseInformation?.invoiceAddressStreet, 300);
  const debouncedReturnStreet = useDebouncedValue(purchaseInformation?.returnAddressStreet, 300);
  const debouncedPickupStreet = useDebouncedValue(purchaseInformation?.pickupAddressStreet, 300);

  const [invoiceAddressPredictions, setInvoiceAddressPredictions] = useState<PlacePrediction[]>([]);
  const [returnAddressPredictions, setReturnAddressPredictions] = useState<PlacePrediction[]>([]);
  const [pickupAddressPredictions, setPickupAddressPredictions] = useState<PlacePrediction[]>([]);

  // purchaseFormFetcher is stable
  const { fetch: purchaseFormFetcher } = useAsyncValue(fetchPurchaseForm);

  const invoiceRegion = purchaseInformation?.invoiceAddressCountry ?? DEFAULT_COUNTRY;
  const returnRegion = purchaseInformation?.returnAddressCountry ?? DEFAULT_COUNTRY;
  const pickupRegion = purchaseInformation?.pickupAddressCountry ?? DEFAULT_COUNTRY;

  const { value, heyflowId, transportType } = useHeyFlowLeadContext();
  const lead = value?.lead;
  const cart = value?.cart;

  // automatically load entry from database on startup
  useEffect(() => {
    (async () => {
      if (!heyflowId) {
        return;
      }

      const fetchedPurchaseInformation = await purchaseFormFetcher(heyflowId);
      if (fetchedPurchaseInformation) {
        setPurchaseInformation(fetchedPurchaseInformation);
        hasDatabaseEntry.current = true;
        return;
      } else {
        hasDatabaseEntry.current = false;
      }
    })();
  }, [heyflowId, purchaseFormFetcher]);

  const storePurchaseInformation = useCallback(
    async (purchaseInformation: PurchaseInformation | undefined, force?: boolean) => {
      if (heyflowId === undefined || purchaseInformation === undefined) {
        return;
      }

      if (!force && !hasBeenUserEdited.current && hasDatabaseEntry.current !== true) {
        return;
      }

      hasDatabaseEntry.current = true;
      await storePurchaseInformationForHeyFlowId(heyflowId, purchaseInformation);
    },
    [heyflowId]
  );

  useRegularUpdate(purchaseInformation, storePurchaseInformation, 4000);

  // invoice address predictions
  useEffect(() => {
    (async () => {
      if (debouncedInvoiceStreet && dirty.current.invoiceAddressStreet) {
        const region = COUNTRIES.find((country) => country.value === invoiceRegion)!.mapsRegion;
        const predictions = await fetchPlaceAutocomplete(debouncedInvoiceStreet, region);

        setInvoiceAddressPredictions(predictions);
      }
    })();
  }, [debouncedInvoiceStreet, invoiceRegion]);

  const cancelInvoiceAddressPrediction = useCallback(() => {
    setInvoiceAddressPredictions([]);
  }, []);

  const selectInvoiceAddressPrediction = useCallback(async (newValue: string, placeId: string) => {
    setPurchaseInformation((purchaseInformation) =>
      purchaseInformation !== undefined ? { ...purchaseInformation, invoiceAddressStreet: newValue } : undefined
    );
    setInvoiceAddressPredictions([]);
    hasBeenUserEdited.current = true;
    const { street, streetNumber, zipCode, city } = await fetchPlaceDetails(placeId);

    const newStreet = `${street ?? ""} ${streetNumber ?? ""}`.trim();
    const newZipCode = zipCode ?? "";
    const newCity = city ?? "";
    setPurchaseInformation((purchaseInformation) =>
      purchaseInformation
        ? {
            ...purchaseInformation,
            invoiceAddressStreet: newStreet,
            invoiceAddressZipCode: newZipCode,
            invoiceAddressCity: newCity,
          }
        : undefined
    );

    setErrors((errors) => ({
      ...errors,
      invoiceAddressStreet: newStreet === "" ? errors.invoiceAddressStreet : undefined,
      invoiceAddressZipCode: newZipCode === "" ? errors.invoiceAddressZipCode : undefined,
      invoiceAddressCity: newCity === "" ? errors.invoiceAddressCity : undefined,
    }));

    dirty.current = { ...dirty.current, invoiceAddressStreet: false };
  }, []);

  // return address predictions
  useEffect(() => {
    (async () => {
      if (debouncedReturnStreet && dirty.current.returnAddressStreet) {
        const region = COUNTRIES.find((country) => country.value === returnRegion)!.mapsRegion;
        const predictions = await fetchPlaceAutocomplete(debouncedReturnStreet, region);

        setReturnAddressPredictions(predictions);
      }
    })();
  }, [debouncedReturnStreet, returnRegion]);

  const cancelReturnAddressPrediction = useCallback(() => {
    setReturnAddressPredictions([]);
  }, []);

  const selectReturnAddressPrediction = useCallback(async (newValue: string, placeId: string) => {
    setPurchaseInformation((purchaseInformation) =>
      purchaseInformation !== undefined ? { ...purchaseInformation, returnAddressStreet: newValue } : undefined
    );
    setReturnAddressPredictions([]);
    hasBeenUserEdited.current = true;
    const { street, streetNumber, zipCode, city } = await fetchPlaceDetails(placeId);

    const newStreet = `${street ?? ""} ${streetNumber ?? ""}`.trim();
    const newZipCode = zipCode ?? "";
    const newCity = city ?? "";
    setPurchaseInformation((purchaseInformation) =>
      purchaseInformation
        ? {
            ...purchaseInformation,
            returnAddressStreet: newStreet,
            returnAddressZipCode: newZipCode,
            returnAddressCity: newCity,
          }
        : undefined
    );

    setErrors((errors) => ({
      ...errors,
      returnAddressStreet: newStreet === "" ? errors.returnAddressStreet : undefined,
      returnAddressZipCode: newZipCode === "" ? errors.returnAddressZipCode : undefined,
      returnAddressCity: newCity === "" ? errors.returnAddressCity : undefined,
    }));

    dirty.current = { ...dirty.current, returnAddressStreet: false };
  }, []);

  // pickup address predictions
  useEffect(() => {
    (async () => {
      if (debouncedPickupStreet && dirty.current.pickupAddressStreet) {
        const region = COUNTRIES.find((country) => country.value === pickupRegion)!.mapsRegion;
        const predictions = await fetchPlaceAutocomplete(debouncedPickupStreet, region);

        setPickupAddressPredictions(predictions);
      }
    })();
  }, [debouncedPickupStreet, pickupRegion]);

  const cancelPickupAddressPrediction = useCallback(() => {
    setPickupAddressPredictions([]);
  }, []);

  const selectPickupAddressPrediction = useCallback(async (newValue: string, placeId: string) => {
    setPurchaseInformation((purchaseInformation) =>
      purchaseInformation !== undefined ? { ...purchaseInformation, pickupAddressStreet: newValue } : undefined
    );
    setPickupAddressPredictions([]);
    hasBeenUserEdited.current = true;
    const { street, streetNumber, zipCode, city } = await fetchPlaceDetails(placeId);

    const newStreet = `${street ?? ""} ${streetNumber ?? ""}`.trim();
    const newZipCode = zipCode ?? "";
    const newCity = city ?? "";

    setPurchaseInformation((purchaseInformation) =>
      purchaseInformation
        ? {
            ...purchaseInformation,
            pickupAddressStreet: newStreet,
            pickupAddressZipCode: newZipCode,
            pickupAddressCity: newCity,
          }
        : undefined
    );

    setErrors((errors) => ({
      ...errors,
      pickupAddressStreet: newStreet === "" ? errors.pickupAddressStreet : undefined,
      pickupAddressZipCode: newZipCode === "" ? errors.pickupAddressZipCode : undefined,
      pickupAddressCity: newCity === "" ? errors.pickupAddressCity : undefined,
    }));

    dirty.current = { ...dirty.current, pickupAddressStreet: false };
  }, []);

  useEffect(() => {
    if (!lead || hasDatabaseEntry.current === true || hasBeenUserEdited.current) {
      return;
    }

    const nameParts = lead.name?.trim().split(/\s+/) ?? [""];
    const lastName = nameParts.length > 1 ? (nameParts.pop() ?? "") : "";

    setPurchaseInformation({
      email: lead.email,
      invoiceAddressCountry: DEFAULT_COUNTRY,
      invoiceAddressFirstName: nameParts.join(" "),
      invoiceAddressLastName: lastName,
      invoiceAddressCompany: "",
      invoiceAddressStreet: "",
      invoiceAddressZipCode: lead.location ?? "",
      invoiceAddressCity: "",
      invoiceAddressPhoneCountryCode: DEFAULT_COUNTRY_CODE,
      invoiceAddressPhoneNumber: "",

      hasExtraReturnAddress: false,
      returnAddressCountry: DEFAULT_COUNTRY,
      returnAddressFirstName: "",
      returnAddressLastName: "",
      returnAddressCompany: "",
      returnAddressStreet: "",
      returnAddressZipCode: "",
      returnAddressCity: "",

      animalType:
        lead.lead.animalType === "dein Hund" ? "dog" : lead.lead.animalType === "deine Katze" ? "cat" : undefined,
      animalName: "",
      animalWeight: "",
      animalStage:
        lead.lead.concern === "verstorben" ? "dead" : lead.lead.concern === "anstehend" ? "soon" : "forehand",

      inscription: "",
      hasExtraPickupAddress: false,

      pickupAddressCountry: DEFAULT_COUNTRY,
      pickupAddressPracticeName: "",
      pickupAddressStreet: "",
      pickupAddressZipCode: "",
      pickupAddressCity: "",
      pickupAddressPhoneCountryCode: DEFAULT_COUNTRY_CODE,
      pickupAddressPhoneNumber: "",

      returnType: "dhl",
      remarks: "",
    });
  }, [lead]);

  const updateField = useCallback(<T extends FormKeys>(key: T, value: PurchaseInformation[T]) => {
    const validator = purchaseValidators[key] as (a: Argument<(typeof purchaseValidators)[T]>) => unknown;

    dirty.current = { ...dirty.current, [key]: true };
    try {
      validator(value);
      setErrors((prev) => ({ ...prev, [key]: undefined }));
    } catch (error) {
      let message = "Unknown error";
      if (error instanceof Error) message = error.message;
      setErrors((prev) => ({ ...prev, [key]: prev[key] !== undefined ? message : undefined }));
    }

    if (key === "hasExtraReturnAddress" && value === false) {
      setReturnAddressPredictions([]);
    }
    if (key === "hasExtraPickupAddress" && value === false) {
      setPickupAddressPredictions([]);
    }
    setPurchaseInformation((prev) => (prev !== undefined ? { ...prev, [key]: value } : undefined));
    hasBeenUserEdited.current = true;
  }, []);

  const onInvoiceAddressCountryChange = useCallback(
    (newInvoiceAddressCountry: Country) => {
      if (!purchaseInformation?.invoiceAddressPhoneNumber) {
        updateField("invoiceAddressPhoneCountryCode", countryCodeForCountry(newInvoiceAddressCountry));
      }
    },
    [purchaseInformation?.invoiceAddressPhoneNumber, updateField]
  );

  const onPickupAddressCountryChange = useCallback(
    (newPickupAddressCountry: Country) => {
      if (!purchaseInformation?.pickupAddressPhoneNumber) {
        updateField("pickupAddressPhoneCountryCode", countryCodeForCountry(newPickupAddressCountry));
      }
    },
    [purchaseInformation?.pickupAddressPhoneNumber, updateField]
  );

  const submit = useCallback(
    (purchaseInformation: PurchaseInformation) => {
      const result = {} as ValidPurchaseInformation;
      const errors = {} as PurchaseFormErrors;
      let hasErrors = false;

      Object.entries(purchaseValidators).forEach(([key, validator]) => {
        const typedKey = key as FormKeys;

        const typedValidator = validator as (
          a: Argument<(typeof purchaseValidators)[typeof typedKey]>
        ) => ReturnType<(typeof purchaseValidators)[typeof typedKey]>;

        try {
          let needsValidation = true;

          switch (typedKey) {
            case "returnAddressCountry":
            case "returnAddressFirstName":
            case "returnAddressLastName":
            case "returnAddressStreet":
            case "returnAddressZipCode":
            case "returnAddressCity":
              needsValidation =
                lead?.lead.cremation.type === "Einzelkremierung" &&
                purchaseInformation.returnType === "dhl" &&
                purchaseInformation.hasExtraReturnAddress === true;
              break;

            case "pickupAddressCountry":
            case "pickupAddressPracticeName":
            case "pickupAddressStreet":
            case "pickupAddressZipCode":
            case "pickupAddressCity":
            case "pickupAddressPhoneCountryCode":
            case "pickupAddressPhoneNumber":
              needsValidation = transportType === "pickUp" && purchaseInformation.hasExtraPickupAddress === true;
              break;

            case "inscription":
              needsValidation =
                lead?.lead.cremation.type === "Einzelkremierung" &&
                (cart?.some((cartItem) => cartItem.type === "inscription") ?? false);
              break;
          }

          result[typedKey] = (
            needsValidation ? typedValidator(purchaseInformation[typedKey]) : purchaseInformation[typedKey]
          ) as never;
        } catch (error) {
          let message = "Unknown error";
          if (error instanceof Error) message = error.message;
          errors[typedKey] = message;
          hasErrors = true;
        }
      });

      if (hasErrors) {
        setErrors(errors);
        return errors;
      }

      setPurchaseInformation(result);
      storePurchaseInformation(result);
      return true;
    },
    [cart, lead?.lead.cremation.type, storePurchaseInformation, transportType]
  );

  return {
    purchaseInformation,
    invoiceAddressPredictions,
    cancelInvoiceAddressPrediction,
    selectInvoiceAddressPrediction,
    returnAddressPredictions,
    cancelReturnAddressPrediction,
    selectReturnAddressPrediction,
    pickupAddressPredictions,
    cancelPickupAddressPrediction,
    selectPickupAddressPrediction,
    updateField,
    onInvoiceAddressCountryChange,
    onPickupAddressCountryChange,
    storePurchaseInformation,
    submit,
    errors,
  };
};

const { useContext, Provider } = createContextAndProvider(usePurchase);
export const usePurchaseContext = useContext;
export const PurchaseProvider = Provider;
