import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import { loadStripe } from "@stripe/stripe-js/pure";
import * as Sentry from "@sentry/react";

import "./index.css";
import { ContextProviders } from "./contexts/ContextProviders";
import { Main } from "./pages/Main";
import { Products } from "./pages/Products";
import { Product } from "./pages/Product";
import { Root } from "./pages/Root";
import { CartEntry } from "./pages/CartEntry";
import { Purchase } from "./pages/Purchase";
import { CheckPurchase } from "./pages/CheckPurchase";
import { InitPage } from "./pages/Init";
import { PurchaseComplete } from "./pages/PurchaseComplete";
import configuration from "./configuration";
import { Elements } from "@stripe/react-stripe-js";
import { getStripeOptions } from "./utils/stripe";
import { usePaymentContext } from "./contexts/payment";

Sentry.init({
  dsn: "https://94505423db2d0d7cc6840b93cf8a58ab@o4508132941168640.ingest.de.sentry.io/4508132971774032",
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: ":heyflowId?",
        element: <Main />,
      },
      {
        path: ":heyflowId/products/:type/:page?",
        element: <Products />,
      },
      {
        path: ":heyflowId/product/:productId",
        element: <Product />,
      },
      {
        path: ":heyflowId/cart/:cartItemIndex",
        element: <CartEntry />,
      },
      {
        path: ":heyflowId/purchase",
        element: <Purchase />,
      },
      {
        path: ":heyflowId/check",
        element: <CheckPurchase />,
      },
      {
        path: ":heyflowId/init/:data",
        element: <InitPage />,
      },
      {
        path: ":heyflowId/purchase-complete",
        element: <PurchaseComplete />,
      },
    ],
  },
]);

const stripeApiKey = configuration.stripe.testMode
  ? configuration.stripe.publicApiKeyTest
  : configuration.stripe.publicApiKeyProduction;

const stripePromise = (async () => {
  // eslint-disable-next-line no-constant-condition
  while (true) {
    try {
      return await loadStripe(stripeApiKey);
    } catch (err) {
      console.error(err);
      await new Promise((resolve) => setTimeout(resolve, 5000));
    }
  }
})();

function StripeWrapper() {
  const { totalPriceCents, stripeCustomerSession } = usePaymentContext();

  return (
    <Elements
      options={getStripeOptions(totalPriceCents === 0 ? 100 : totalPriceCents, stripeCustomerSession?.clientSecret)}
      stripe={stripePromise}
    >
      <RouterProvider router={router} />
    </Elements>
  );
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ContextProviders>
      <StripeWrapper />
    </ContextProviders>
  </React.StrictMode>
);
