import { Link, useNavigate } from "react-router-dom";

import { Lead, useHeyFlowLeadContext } from "../contexts/heyFlowLead";
import { usePurchaseContext } from "../contexts/purchase";
import { createAddressString } from "../utils/address";
import { usePaymentContext } from "../contexts/payment";
import { FormEvent, useCallback, useEffect, useState } from "react";
import { PaymentMethodPreview } from "./PaymentMethodPreview";
import { Button } from "./Button";
import { useStripe } from "@stripe/react-stripe-js";
import { StripeError } from "@stripe/stripe-js";
import { FormErrorMessage } from "./FormErrorMessage";

interface Props {
  lead: Lead;
  heyflowId: string;
}

export function PurchaseDetailsCheck({ heyflowId, lead }: Props) {
  const { transportType } = useHeyFlowLeadContext();
  const { purchaseInformation } = usePurchaseContext();
  const { confirmationToken, confirmationTokenUsed, createPaymentIntent, confirmPayment } = usePaymentContext();
  const navigate = useNavigate();
  const stripe = useStripe();

  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const handleError = (error: StripeError) => {
    setIsLoading(false);
    setErrorMessage(error.message);
  };

  const {
    lead: { cremation },
  } = lead;

  useEffect(() => {
    if (!purchaseInformation || !confirmationToken) {
      navigate(`/${heyflowId}/purchase`);
      return;
    }
  }, [confirmationToken, heyflowId, navigate, purchaseInformation]);

  const onFormSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (!stripe || isLoading) return;
      setIsLoading(true);

      if (confirmationTokenUsed) {
        navigate(`/${heyflowId}/purchase`, { state: { scroll: "payment", payment: "open" } });
        return;
      }

      const origin = window.location.origin;
      const returnUrl = new URL(`/${heyflowId}/purchase-complete`, origin).href;

      try {
        const clientSecret = await createPaymentIntent(heyflowId);
        if (!clientSecret) {
          return;
        }

        const confirmResult = await confirmPayment(stripe, returnUrl, clientSecret);
        if (confirmResult?.error) {
          handleError(confirmResult.error);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [confirmPayment, confirmationTokenUsed, createPaymentIntent, heyflowId, isLoading, navigate, stripe]
  );

  if (!purchaseInformation || !confirmationToken) {
    return null;
  }

  const paymentMethodPreview = confirmationToken.payment_method_preview;

  const {
    invoiceAddressFirstName,
    invoiceAddressLastName,
    invoiceAddressStreet,
    invoiceAddressZipCode,
    invoiceAddressCity,
    invoiceAddressCountry,

    hasExtraReturnAddress,
    returnAddressFirstName,
    returnAddressLastName,
    returnAddressStreet,
    returnAddressZipCode,
    returnAddressCity,
    returnAddressCountry,

    hasExtraPickupAddress,
    pickupAddressPracticeName,
    pickupAddressStreet,
    pickupAddressZipCode,
    pickupAddressCity,
    pickupAddressCountry,

    returnType,
  } = purchaseInformation;

  const invoiceAddress = createAddressString(
    `${invoiceAddressFirstName} ${invoiceAddressLastName}`,
    invoiceAddressStreet,
    invoiceAddressZipCode,
    invoiceAddressCity,
    invoiceAddressCountry
  );

  const returnAddress = hasExtraReturnAddress
    ? createAddressString(
        `${returnAddressFirstName} ${returnAddressLastName}`,
        returnAddressStreet,
        returnAddressZipCode,
        returnAddressCity,
        returnAddressCountry
      )
    : invoiceAddress;

  const pickupAddress = hasExtraPickupAddress
    ? createAddressString(
        pickupAddressPracticeName,
        pickupAddressStreet,
        pickupAddressZipCode,
        pickupAddressCity,
        pickupAddressCountry
      )
    : invoiceAddress;

  let returnString;
  switch (returnType) {
    case "dhl":
      returnString = "Rückführung per DHL – 10 €";
      break;
    case "pickup":
      returnString = "Abholung am Standort – kostenfrei";
      break;
  }

  return (
    <form onSubmit={onFormSubmit}>
      <div className="flex flex-row items-start justify-between lg:pt-6">
        <div className="text-base font-medium text-neutral-500 pb-2">Kontakt</div>
        <Link
          to={`/${heyflowId}/purchase`}
          state={{ scroll: "contact" }}
          className="text-sm text-neutral-900 underline"
        >
          Ändern
        </Link>
      </div>
      <div className="text-sm text-neutral-900">{purchaseInformation.email}</div>
      <div className="border-t border-t-slate-300 my-4"></div>

      <div className="flex flex-row items-start justify-between">
        <div className="text-base font-medium text-neutral-500 pb-2">Rechnungsadresse</div>
        <Link
          to={`/${heyflowId}/purchase`}
          state={{ scroll: "invoiceaddress" }}
          className="text-sm text-neutral-900 underline"
        >
          Ändern
        </Link>
      </div>
      <div className="text-sm text-neutral-900">{invoiceAddress}</div>
      <div className="border-t border-t-slate-300 my-4"></div>

      {transportType === "pickUp" && (
        <>
          <div className="flex flex-row items-start justify-between">
            <div className="text-base font-medium text-neutral-500 pb-2">Abholadresse</div>
            <Link
              to={`/${heyflowId}/purchase`}
              state={{ scroll: "pickupaddress" }}
              className="text-sm text-neutral-900 underline"
            >
              Ändern
            </Link>
          </div>
          <div className="text-sm text-neutral-900">{pickupAddress}</div>
          <div className="border-t border-t-slate-300 my-4"></div>
        </>
      )}

      {cremation.type === "Einzelkremierung" && (
        <>
          <div className="flex flex-row items-start justify-between">
            <div className="text-base font-medium text-neutral-500 pb-2">Rückführung</div>
            <Link
              to={`/${heyflowId}/purchase`}
              state={{ scroll: "returnaddress" }}
              className="text-sm text-neutral-900 underline"
            >
              Ändern
            </Link>
          </div>
          <div className="text-sm text-neutral-900">{returnString}</div>
          {purchaseInformation.returnType === "dhl" && <div className="text-sm text-neutral-900">{returnAddress}</div>}
          <div className="border-t border-t-slate-300 my-4"></div>
        </>
      )}

      <div className="flex flex-row items-start justify-between mb-3">
        <div className="text-base font-medium text-neutral-500 pb-2">Zahlungsmethode</div>
        <Link
          to={`/${heyflowId}/purchase`}
          state={{ scroll: "payment", payment: "open" }}
          className="text-sm text-neutral-900 underline"
        >
          Ändern
        </Link>
      </div>
      <div className="text-sm text-neutral-900">
        <PaymentMethodPreview paymentMethodPreview={paymentMethodPreview} />
      </div>
      <div className="border-t border-t-slate-300 my-4"></div>
      <FormErrorMessage message={errorMessage} />
      <Button
        type="submit"
        disabled={!stripe || isLoading}
        inactive={isLoading}
        caption={confirmationTokenUsed ? "Zahlungsdaten bestätigen" : "Jetzt abschließen"}
        fullWidth={true}
        primary={true}
        large={true}
        className="mt-7"
        showSpinner={isLoading}
      ></Button>
    </form>
  );
}
