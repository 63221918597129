import { useLocation } from "react-router-dom";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import * as Sentry from "@sentry/browser";

import { Lead, useHeyFlowLeadContext } from "../contexts/heyFlowLead";
import { PurchaseRemarks } from "./purchaseForm/PurchaseRemarks";
import { PurchaseReturnType } from "./purchaseForm/PurchaseReturnType";
import { PurchaseUrn } from "./purchaseForm/PurchaseUrn";
import { PurchasePetInfo } from "./purchaseForm/PurchasePetInfo";
import { PurchaseReturnAddress } from "./purchaseForm/PurchaseReturnAddress";
import { PurchaseInvoiceAddress } from "./purchaseForm/PurchaseInvoiceAddress";
import { PurchaseContact } from "./purchaseForm/PurchaseContact";
import { PurchasePickupAddress } from "./purchaseForm/PurchasePickupAddress";
import { usePurchaseContext } from "../contexts/purchase";
import { Button } from "./Button";
import { FormEvent, useCallback, useEffect, useRef, useState } from "react";
import { PurchasePayment } from "./purchaseForm/PurchasePayment";
import { usePaymentContext } from "../contexts/payment";
import { FormErrorMessage } from "./FormErrorMessage";

interface Props {
  lead: Lead;
  heyflowId: string;
  proceedToCheck: () => void;
}

export function PurchaseDetails({ lead, heyflowId, proceedToCheck }: Props) {
  const { transportType, requiresInscription, allowInscriptionInput } = useHeyFlowLeadContext();

  const { submit, purchaseInformation } = usePurchaseContext();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isPaymentElementReady, setIsPaymentElementReady] = useState<boolean>(false);
  const [hasPaymentElementLoadError, setHasPaymentElementLoadError] = useState<boolean>(false);

  const { createConfirmationToken, message, isEditingPaymentMethod, setIsEditingPaymentMethod, confirmationToken } =
    usePaymentContext();

  const { state } = useLocation();

  const stripe = useStripe();
  const elements = useElements();

  const contactRef = useRef<HTMLDivElement>(null);
  const invoiceAddressRef = useRef<HTMLDivElement>(null);
  const pickupAddressRef = useRef<HTMLDivElement>(null);
  const petInfoRef = useRef<HTMLDivElement>(null);
  const urnRef = useRef<HTMLDivElement>(null);
  const returnTypeRef = useRef<HTMLDivElement>(null);
  const returnAddressRef = useRef<HTMLDivElement>(null);
  const remarksRef = useRef<HTMLDivElement>(null);
  const paymentRef = useRef<HTMLDivElement>(null);

  const {
    lead: { cremation },
  } = lead;

  useEffect(() => {
    switch (state?.scroll) {
      case "contact":
        contactRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case "invoiceaddress":
        invoiceAddressRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case "pickupaddress":
        pickupAddressRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case "returnaddress":
        returnTypeRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case "payment":
        paymentRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
    }
  }, [state?.scroll]);

  useEffect(() => {
    if (state?.payment === "open") {
      setIsEditingPaymentMethod(true);
    }
  }, [setIsEditingPaymentMethod, state?.payment]);

  const onFormSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (!stripe || !elements || !purchaseInformation || isSubmitting) {
        return;
      }

      setIsSubmitting(true);

      try {
        const submissionResult = submit(purchaseInformation);

        if (submissionResult !== true) {
          const errors = submissionResult;

          switch (true) {
            case !!errors.email:
              contactRef.current?.scrollIntoView({ behavior: "smooth" });
              break;

            case !!errors.invoiceAddressCountry:
            case !!errors.invoiceAddressFirstName:
            case !!errors.invoiceAddressLastName:
            case !!errors.invoiceAddressCompany:
            case !!errors.invoiceAddressStreet:
            case !!errors.invoiceAddressZipCode:
            case !!errors.invoiceAddressCity:
            case !!errors.invoiceAddressPhoneCountryCode:
            case !!errors.invoiceAddressPhoneNumber:
              invoiceAddressRef.current?.scrollIntoView({ behavior: "smooth" });
              break;

            // pickup address
            case !!errors.hasExtraPickupAddress:
            case !!errors.pickupAddressCountry:
            case !!errors.pickupAddressPracticeName:
            case !!errors.pickupAddressStreet:
            case !!errors.pickupAddressZipCode:
            case !!errors.pickupAddressCity:
            case !!errors.pickupAddressPhoneCountryCode:
            case !!errors.pickupAddressPhoneNumber:
              pickupAddressRef.current?.scrollIntoView({ behavior: "smooth" });
              break;

            // pet
            case !!errors.animalType:
            case !!errors.animalName:
            case !!errors.animalWeight:
            case !!errors.animalStage:
              petInfoRef.current?.scrollIntoView({ behavior: "smooth" });
              break;

            // urn
            case !!errors.inscription:
              urnRef.current?.scrollIntoView({ behavior: "smooth" });
              break;

            // return
            case !!errors.returnType:
              returnTypeRef.current?.scrollIntoView({ behavior: "smooth" });
              break;

            // return address
            case !!errors.hasExtraReturnAddress:
            case !!errors.returnAddressCountry:
            case !!errors.returnAddressFirstName:
            case !!errors.returnAddressLastName:
            case !!errors.returnAddressCompany:
            case !!errors.returnAddressStreet:
            case !!errors.returnAddressZipCode:
            case !!errors.returnAddressCity:
              returnAddressRef.current?.scrollIntoView({ behavior: "smooth" });
              break;

            // remarks
            case !!errors.remarks:
              remarksRef.current?.scrollIntoView({ behavior: "smooth" });
              break;
          }

          return;
        }

        if (!confirmationToken || isEditingPaymentMethod) {
          const confirmationTokenCreated = await createConfirmationToken(stripe, elements, purchaseInformation);

          const hasPaymentFormError = !confirmationTokenCreated;
          if (hasPaymentFormError) {
            paymentRef.current?.scrollIntoView({ behavior: "smooth" });
            return;
          }
        }

        setIsEditingPaymentMethod(false);
        proceedToCheck();
      } finally {
        setIsSubmitting(false);
      }
    },
    [
      stripe,
      elements,
      purchaseInformation,
      isSubmitting,
      submit,
      confirmationToken,
      isEditingPaymentMethod,
      setIsEditingPaymentMethod,
      proceedToCheck,
      createConfirmationToken,
    ]
  );

  const paymentError = hasPaymentElementLoadError
    ? "Es gab einen Fehler beim Anzeigen der Zahlungsmethode. Bitte neuladen"
    : message;

  const buttonWaitsForPaymentElement = !(confirmationToken && !isEditingPaymentMethod) && !isPaymentElementReady;

  return (
    <form onSubmit={onFormSubmit}>
      <div ref={contactRef} />
      <PurchaseContact />
      <div ref={invoiceAddressRef} />
      <PurchaseInvoiceAddress />
      <div ref={pickupAddressRef} />
      {transportType === "pickUp" && <PurchasePickupAddress />}
      <div ref={petInfoRef} />
      <PurchasePetInfo />
      <div ref={urnRef} />
      {cremation.type === "Einzelkremierung" && (requiresInscription || allowInscriptionInput) && (
        <PurchaseUrn
          heyflowId={heyflowId}
          lead={lead}
          requiresInscription={requiresInscription}
          allowInscriptionInput={allowInscriptionInput}
        />
      )}
      <div ref={returnTypeRef} />
      {cremation.type === "Einzelkremierung" && <PurchaseReturnType />}
      <div ref={returnAddressRef} />
      {cremation.type === "Einzelkremierung" && <PurchaseReturnAddress />}
      <div ref={remarksRef} />
      <PurchaseRemarks />
      <div ref={paymentRef} />
      <PurchasePayment
        isEditingPaymentMethod={isEditingPaymentMethod}
        setIsEditingPaymentMethod={setIsEditingPaymentMethod}
        confirmationToken={confirmationToken}
        onPaymentElementReady={() => setIsPaymentElementReady(true)}
        onPaymentElementLoadError={() => {
          Sentry.captureMessage("Payment element could not be loaded");
          setHasPaymentElementLoadError(true);
        }}
      />
      <FormErrorMessage message={paymentError} />
      {!hasPaymentElementLoadError ? (
        <Button
          type="submit"
          disabled={!stripe || !elements || isSubmitting || buttonWaitsForPaymentElement}
          inactive={isSubmitting || buttonWaitsForPaymentElement}
          caption={!buttonWaitsForPaymentElement ? "Auftrag überprüfen" : "Bitte warten"}
          fullWidth={true}
          primary={true}
          large={true}
          className="mt-7"
          showSpinner={isSubmitting || buttonWaitsForPaymentElement}
        ></Button>
      ) : (
        <>
          <Button
            type="button"
            caption="Seite neuladen"
            fullWidth={true}
            primary={true}
            large={true}
            className="mt-3"
            onClick={() => window.location.reload()}
          ></Button>
        </>
      )}
    </form>
  );
}
