/* eslint-disable @typescript-eslint/no-explicit-any */
import amex from "./assets/amex.png";
import klarna from "./assets/klarna.png";
import mastercard from "./assets/mastercard.png";
import paypal from "./assets/paypal.png";
import sepa from "./assets/sepa.svg";
import sofort from "./assets/sofort.svg";
import visa from "./assets/visa.png";

interface Props {
  paymentMethodPreview: any;
}

function capitalizeFirst(str: string): string {
  return str
    .replace(/_/g, " ")
    .trim()
    .split(/\s+/)
    .map((str) => str.charAt(0).toUpperCase() + str.slice(1))
    .join(" ");
}

export function PaymentMethodPreview({ paymentMethodPreview }: Props) {
  const { type } = paymentMethodPreview;

  switch (type) {
    case "card":
      switch (paymentMethodPreview.card?.brand) {
        case "amex":
          return (
            <span className="flex items-center gap-3">
              <img src={amex} width="50" />
              Endziffer ...{paymentMethodPreview.card?.last4}
            </span>
          );
        case "visa":
          return (
            <span className="flex items-center gap-3">
              <img src={visa} width="50" />
              Endziffer ...{paymentMethodPreview.card?.last4}
            </span>
          );
        case "mastercard":
          return (
            <span className="flex items-center gap-3">
              <img src={mastercard} width="50" />
              Endziffer ...{paymentMethodPreview.card?.last4}
            </span>
          );
      }
      return <span>Kreditkarte, Endziffer ...{paymentMethodPreview.card?.last4}</span>;

    case "klarna":
      return (
        <span>
          <img src={klarna} width="60" />
        </span>
      );

    case "paypal":
      return (
        <span>
          <img src={paypal} width="70" />
        </span>
      );

    case "sepa_debit":
      return (
        <span className="flex items-center gap-3">
          <img src={sepa} width="60" />
          Endziffer ...{(paymentMethodPreview as any).sepa_debit?.last4}
        </span>
      );

    case "sofort":
      return (
        <span>
          <img src={sofort} width="60" />
        </span>
      );

    default:
      return <span>{capitalizeFirst(paymentMethodPreview.type)}</span>;
  }
}
