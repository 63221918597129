export interface SpinnerProps {
  size: "small" | "medium" | "large";
  color?: "white" | "grey";
}

export function Spinner(props: SpinnerProps) {
  let sizeClasses: string;

  switch (props.size) {
    case "small":
      sizeClasses = "border-4 w-8 h-8";
      break;
    case "medium":
      sizeClasses = "border-6 w-20 h-20";
      break;
    case "large":
      sizeClasses = "border-8 w-32 h-32";
      break;
  }

  const colorClasses = props.color === undefined || props.color === "grey" ? `border-gray-400` : `border-white`;

  return (
    <div className={`${colorClasses} w-32 h-32 border-t-transparent rounded-full animate-spin ${sizeClasses}`}></div>
  );
}
