import { usePurchaseContext } from "../../contexts/purchase";
import { FancyFormTextInput } from "../FancyTextInput";
import { FormErrorMessage } from "../FormErrorMessage";

export function PurchaseContact() {
  const { purchaseInformation, errors, updateField } = usePurchaseContext();

  return (
    <>
      <div className="font-bold max-lg:mt-2 lg:mt-7 mb-3">Kontakt</div>
      <FancyFormTextInput
        label="E-Mail"
        type="email"
        formKey="email"
        formValue={purchaseInformation}
        errors={errors}
        setFormValue={updateField}
      />
      <FormErrorMessage formKeys={["email"]} />
    </>
  );
}
