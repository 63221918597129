import { usePurchaseContext } from "../../contexts/purchase";
import { FancyFormTextInput } from "../FancyTextInput";
import { FormErrorMessage } from "../FormErrorMessage";
import { SelectBox } from "../SelectBox";

export function PurchasePetInfo() {
  const { purchaseInformation, errors, updateField } = usePurchaseContext();

  return (
    <>
      <div className="font-bold mt-7 mb-3">Infos zu deinem Tier</div>
      <SelectBox
        options={[
          { text: "Was hast du für ein Haustier?", value: "none" },
          { text: "Katze", value: "cat" },
          { text: "Hund", value: "dog" },
          { text: "Kleintier/Anderes", value: "small" },
        ]}
        value={purchaseInformation?.animalType}
        setValue={(value) => updateField("animalType", value === "none" ? undefined : value)}
        defaultValue={"none"}
        hasError={errors.animalType !== undefined}
      />
      <div className="flex gap-4 py-3">
        <FancyFormTextInput
          label="Tiername"
          type="text"
          formKey="animalName"
          formValue={purchaseInformation}
          setFormValue={updateField}
          errors={errors}
        />
        <FancyFormTextInput
          label="Ungefähres Gewicht"
          type="text"
          formKey="animalWeight"
          formValue={purchaseInformation}
          setFormValue={updateField}
          errors={errors}
        />
      </div>
      <SelectBox
        options={[
          { text: "Bereits verstorben", value: "dead" },
          { text: "Tierarzt-Termin steht bevor", value: "soon" },
          { text: "Vorsorglicher Auftrag", value: "forehand" },
        ]}
        value={purchaseInformation?.animalStage}
        setValue={(value) => updateField("animalStage", value)}
        hasError={errors.animalStage !== undefined}
      />
      <FormErrorMessage formKeys={["animalType", "animalName", "animalWeight", "animalStage"]} />
    </>
  );
}
