import { useCallback, useMemo, useState } from "react";
import {
  ConfirmationToken,
  PaymentIntent,
  PaymentIntentResult,
  PaymentMethodCreateParams,
  Stripe,
  StripeElements,
  StripeError,
} from "@stripe/stripe-js";
import * as Sentry from "@sentry/browser";

import { createContextAndProvider } from ".";
import { useHeyFlowLeadContext } from "./heyFlowLead";
import { PurchaseInformation, usePurchaseContext } from "./purchase";
import configuration from "../configuration";
import { sha256 } from "../utils/hash";
import { useAsyncValue } from "../hooks/useAsyncValue";
import {
  fetchNewPaymentIntent,
  fetchPaymentMethodPreviewAndOrderId,
  retrieveStripeCustomerSession,
} from "../utils/api";

export type PaymentStatus = "pending" | "success" | "error" | "cancelled";

export interface PaymentIntentResponse {
  clientSecret: string;
}

const FINAL_HEADLINES: Record<PaymentIntent.Status, string> = {
  canceled: "Zahlung abgebrochen",
  processing: "Bitte warten",
  requires_action: "",
  requires_capture: "",
  requires_confirmation: "",
  requires_payment_method: "",
  succeeded: "Vielen Dank für dein Vertrauen",
};

const PAYMENT_STATUSES: Record<PaymentIntent.Status, PaymentStatus> = {
  canceled: "cancelled",
  processing: "pending",
  requires_action: "error",
  requires_capture: "error",
  requires_confirmation: "error",
  requires_payment_method: "error",
  succeeded: "success",
};

export interface StripeCustomerSessionInfo {
  clientSecret: string;
  expiresAt: number;
}

async function retrievePaymentIntent(stripe: Stripe, clientSecret: string): Promise<PaymentIntentResult> {
  const paymentIntent = await stripe.retrievePaymentIntent(clientSecret);
  return paymentIntent;
}

const usePayment = () => {
  const { value: leadAndCart, reloadLeadAndCart } = useHeyFlowLeadContext();
  const { purchaseInformation, storePurchaseInformation } = usePurchaseContext();

  const [message, setMessage] = useState<string | undefined>(undefined);

  const [stripeCustomerSession, setStripeCustomerSession] = useState<StripeCustomerSessionInfo | undefined>(undefined);

  const [confirmationToken, setConfirmationToken] = useState<ConfirmationToken | undefined>(undefined);
  const [confirmationTokenUsed, setConfirmationTokenUsed] = useState<boolean>(false);

  const { fetch: fetchExistingPaymentIntent, value: paymentIntentResult } = useAsyncValue(retrievePaymentIntent);

  const asyncPaymentMethodAndOrderId = useAsyncValue(fetchPaymentMethodPreviewAndOrderId);

  const [isEditingPaymentMethod, setIsEditingPaymentMethod] = useState<boolean>(false);

  const { totalPriceCents, priceWithoutDeliveryCents, deliveryCostCents } = useMemo(() => {
    if (!leadAndCart || !purchaseInformation)
      return { totalPriceCents: 0, priceWithoutDeliveryCents: 0, deliveryCostCents: 0 };

    const { cart, lead } = leadAndCart;

    const isIndividualCremation = lead.lead.cremation.type === "Einzelkremierung";

    const useDhl = purchaseInformation?.returnType !== "pickup";
    const deliveryCostCents =
      isIndividualCremation && useDhl
        ? configuration.deliveryCostInCents.dhl
        : configuration.deliveryCostInCents.pickup;

    const initialSumCents = cart.reduce((a, b) => a + Math.round(b.price * 100), 0);
    const totalPriceCents = initialSumCents + deliveryCostCents;

    return { totalPriceCents, priceWithoutDeliveryCents: initialSumCents, deliveryCostCents };
  }, [leadAndCart, purchaseInformation]);

  const [clientSecret, setClientSecret] = useState<string | undefined>(undefined);

  const createConfirmationToken = useCallback(
    async (stripe: Stripe, elements: StripeElements, purchaseInformation: PurchaseInformation) => {
      const handleError = (error: StripeError) => {
        setMessage(error.message);
      };

      const { error: submitError } = await elements.submit();
      if (submitError) {
        handleError(submitError);
        return false;
      }

      setMessage(undefined);

      const {
        invoiceAddressStreet,
        invoiceAddressZipCode,
        invoiceAddressCity,
        invoiceAddressCountry,
        email,
        invoiceAddressFirstName,
        invoiceAddressLastName,
        invoiceAddressPhoneCountryCode,
        invoiceAddressPhoneNumber,
      } = purchaseInformation;

      const billingDetails: PaymentMethodCreateParams.BillingDetails = {
        address: {
          city: invoiceAddressCity,
          country: invoiceAddressCountry,
          line1: invoiceAddressStreet,
          postal_code: invoiceAddressZipCode,
        },
        email,
        name: `${invoiceAddressFirstName} ${invoiceAddressLastName}`,
        phone: `${invoiceAddressPhoneCountryCode === "other" ? "" : invoiceAddressPhoneCountryCode + " "}${invoiceAddressPhoneNumber}`,
      };

      // Create the ConfirmationToken using the details collected by the Payment Element

      const { error, confirmationToken } = await stripe.createConfirmationToken({
        elements,
        params: {
          payment_method_data: {
            billing_details: billingDetails,
          },
        },
      });

      if (error) {
        handleError(error);
        return false;
      }

      setConfirmationToken(confirmationToken);
      setConfirmationTokenUsed(false);

      return true;
    },
    []
  );

  const createPaymentIntent = useCallback(
    async (heyflowId: string): Promise<string | undefined> => {
      if (!leadAndCart || !confirmationToken) return;

      const hashMaterial = leadAndCart.cart.map((item) => `${item.productId}-${item.variationId ?? ""}`);
      const completeHashMaterial = [...hashMaterial, deliveryCostCents.toString()];
      const hashMaterialString = completeHashMaterial.join(",");

      const cartHash = await sha256(hashMaterialString);

      const createPaymentIntent = async () => {
        const { clientSecret } = await fetchNewPaymentIntent(
          heyflowId,
          cartHash,
          totalPriceCents,
          confirmationToken,
          configuration.stripe.useCustomerSessions
        );
        setClientSecret(clientSecret);

        return clientSecret;
      };

      try {
        // super important to await here
        // otherwise the catch will not execute
        return await createPaymentIntent();
      } catch (error: unknown) {
        if (typeof error === "object" && error !== null && "reload" in error) {
          if (error.reload) {
            console.error("Inconsistent data between backend and frontend");
            await Promise.all([reloadLeadAndCart(), storePurchaseInformation(purchaseInformation, true)]);
            return createPaymentIntent();
          }
        }
      }
    },
    [
      leadAndCart,
      deliveryCostCents,
      totalPriceCents,
      reloadLeadAndCart,
      storePurchaseInformation,
      purchaseInformation,
      confirmationToken,
    ]
  );

  const confirmPayment = useCallback(
    async (stripe: Stripe, returnUrl: string, paymentIntentClientSecret: string) => {
      if (confirmationTokenUsed) return;
      setConfirmationTokenUsed(true);

      const { error } = await stripe.confirmPayment({
        clientSecret: paymentIntentClientSecret,
        confirmParams: {
          confirmation_token: confirmationToken?.id,
          return_url: returnUrl,
          payment_method_data: configuration.stripe.useCustomerSessions ? { allow_redisplay: "always" } : undefined,
        },
      });

      if (error) {
        // This point is only reached if there's an immediate error when
        // confirming the payment. Show the error to your customer (for example, payment details incomplete)
        console.log(error);
        Sentry.captureMessage(`Payment confirmation error: ${error}, ${error.message}`);
        return { error };
      }
    },
    [confirmationToken?.id, confirmationTokenUsed]
  );

  const { finalHeadline, paymentStatus } = useMemo(() => {
    if (!paymentIntentResult) {
      return { finalHeadline: undefined, paymentStatus: undefined };
    }

    if (paymentIntentResult.error) {
      return { finalHeadline: "Dein Zahlungsvorgang" };
    }

    const { status } = paymentIntentResult.paymentIntent;

    return { finalHeadline: FINAL_HEADLINES[status], paymentStatus: PAYMENT_STATUSES[status] };
  }, [paymentIntentResult]);

  const ensureCustomerSession = useCallback(
    async (heyflowId: string) => {
      if (!configuration.stripe.useCustomerSessions) return;

      if (!stripeCustomerSession) {
        const customerSession = await retrieveStripeCustomerSession(heyflowId);
        setStripeCustomerSession(customerSession);
        return;
      }

      if (stripeCustomerSession.expiresAt < Date.now() + 10 * 60 * 1000) {
        const customerSession = await retrieveStripeCustomerSession(heyflowId, true);
        setStripeCustomerSession(customerSession);
      }
    },
    [stripeCustomerSession]
  );

  return {
    clientSecret,
    totalPriceCents,
    priceWithoutDeliveryCents,
    deliveryCostCents,
    confirmationToken,
    confirmationTokenUsed,
    message,
    paymentIntentResult,
    finalHeadline,
    paymentStatus,
    stripeCustomerSession,
    isEditingPaymentMethod,
    asyncPaymentMethodAndOrderId,
    setIsEditingPaymentMethod,
    createPaymentIntent,
    createConfirmationToken,
    fetchExistingPaymentIntent,
    ensureCustomerSession,
    confirmPayment,
  };
};

const { useContext, Provider } = createContextAndProvider(usePayment);
export const usePaymentContext = useContext;
export const PaymentProvider = Provider;
