import { usePurchaseContext } from "../../contexts/purchase";
import { createAddressString } from "../../utils/address";
import { Checkbox } from "../Checkbox";
import { CountrySelect } from "../CountrySelect";
import { Expandable } from "../Expandable";
import { FancyFormTextInput } from "../FancyTextInput";
import { FormErrorMessage } from "../FormErrorMessage";

import pin from "../assets/pin.png";

export function PurchaseReturnAddress() {
  const {
    purchaseInformation,
    errors,
    updateField,
    returnAddressPredictions,
    selectReturnAddressPrediction,
    cancelReturnAddressPrediction,
  } = usePurchaseContext();

  let invoiceAddress: string | undefined;
  let returnAddress: string | undefined;

  if (purchaseInformation !== undefined) {
    const {
      invoiceAddressStreet,
      invoiceAddressZipCode,
      invoiceAddressCity,
      invoiceAddressCountry,
      returnAddressStreet,
      returnAddressZipCode,
      returnAddressCity,
      returnAddressCountry,
    } = purchaseInformation;

    invoiceAddress = createAddressString(
      "",
      invoiceAddressStreet,
      invoiceAddressZipCode,
      invoiceAddressCity,
      invoiceAddressCountry
    );

    returnAddress = createAddressString(
      "",
      returnAddressStreet,
      returnAddressZipCode,
      returnAddressCity,
      returnAddressCountry
    );
  }

  return (
    <>
      <Expandable isOpen={purchaseInformation?.returnType === "dhl"}>
        <div className="font-bold pt-3 mb-0">Rückführungsadresse</div>
        <div className="flex mb-2 items-center">
          <img src={pin} width="20" alt="pin" className="shrink-0 inline mr-3 my-2 lg:my-3 align-middle"></img>
          <div className="text-sm truncate">
            {(purchaseInformation?.hasExtraReturnAddress ? returnAddress : invoiceAddress) ?? ""}
          </div>
        </div>
        <Checkbox
          formKey="hasExtraReturnAddress"
          formValue={purchaseInformation}
          setValue={updateField}
          caption="Rückführungsadresse weicht von der Rechnungsaddress ab"
          className="py-3 px-4 text-sm rounded-md bg-neutral-200"
        />
        <Expandable isOpen={purchaseInformation?.hasExtraReturnAddress ?? false}>
          <div className="pt-3" />
          <CountrySelect
            country={purchaseInformation?.returnAddressCountry}
            setCountry={(country) => {
              updateField("returnAddressCountry", country);
            }}
            hasError={errors.returnAddressCountry !== undefined}
          />
          <div className="flex gap-4 pt-3">
            <FancyFormTextInput
              label="Vorname"
              type="text"
              formKey="returnAddressFirstName"
              formValue={purchaseInformation}
              setFormValue={updateField}
              errors={errors}
            />
            <FancyFormTextInput
              label="Nachname"
              type="text"
              formKey="returnAddressLastName"
              formValue={purchaseInformation}
              setFormValue={updateField}
              errors={errors}
            />
          </div>
          <div className="pt-3" />
          <FancyFormTextInput
            label="Firma (optional)"
            type="text"
            formKey="returnAddressCompany"
            formValue={purchaseInformation}
            setFormValue={updateField}
            errors={errors}
          />
          <div className="pt-3" />
          <FancyFormTextInput
            label="Straße und Hausnummer"
            type="text"
            formKey="returnAddressStreet"
            formValue={purchaseInformation}
            setFormValue={updateField}
            errors={errors}
            mapsPredictions={returnAddressPredictions}
            cancelPredictions={cancelReturnAddressPrediction}
            selectPrediction={selectReturnAddressPrediction}
          />
          <div className="flex gap-4 pt-3">
            <FancyFormTextInput
              label="Postleitzahl"
              type="text"
              formKey="returnAddressZipCode"
              formValue={purchaseInformation}
              setFormValue={updateField}
              errors={errors}
            />
            <FancyFormTextInput
              label="Stadt"
              type="text"
              formKey="returnAddressCity"
              formValue={purchaseInformation}
              setFormValue={updateField}
              errors={errors}
            />
          </div>
          <FormErrorMessage
            formKeys={[
              "returnAddressCountry",
              "returnAddressFirstName",
              "returnAddressLastName",
              "returnAddressStreet",
              "returnAddressZipCode",
              "returnAddressCity",
            ]}
          />
        </Expandable>
      </Expandable>
    </>
  );
}
