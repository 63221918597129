import { SelectBox } from "./SelectBox";

export const COUNTRIES = [
  { text: "Deutschland", value: "DE", mapsRegion: "de" },
  { text: "Niederlande", value: "NL", mapsRegion: "nl" },
  { text: "Belgien", value: "BE", mapsRegion: "be" },
  { text: "Schweiz", value: "CH", mapsRegion: "ch" },
  { text: "Österreich", value: "AT", mapsRegion: "at" },
  { text: "Polen", value: "PL", mapsRegion: "pl" },
] as const;

export type Country = (typeof COUNTRIES)[number]["value"];
export const DEFAULT_COUNTRY: Country = "DE";

interface Props {
  country: Country | undefined;
  hasError: boolean;
  setCountry: (country: Country) => void;
}

export function CountrySelect({ country, hasError, setCountry }: Props) {
  return (
    <SelectBox
      options={COUNTRIES}
      value={country}
      setValue={setCountry}
      defaultValue={DEFAULT_COUNTRY}
      hasError={hasError}
    />
  );
}
