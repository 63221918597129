import { useHeyFlowLeadContext } from "../contexts/heyFlowLead";
import pin from "./assets/pin.png";
import email from "./assets/mail.png";
import user from "./assets/user.png";
import edit from "./assets/editing.png";
import save from "./assets/save.png";

import { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import { Spinner } from "./Spinner";
import { Submit } from "./Submit";
import { Headline } from "./Headline";

interface InputOutputProps {
  isEditing: boolean;
  text: string;
  pattern?: string;
  type?: string;
  className?: string;
  onChange: (text: string) => void;
  onPressEnter: () => void;
}

function _InputOutput(props: InputOutputProps, ref: React.ForwardedRef<HTMLInputElement>) {
  return !props.isEditing ? (
    <span className="overflow-auto">{props.text}</span>
  ) : (
    <input
      type={props.type ?? "text"}
      ref={ref}
      className={`px-3 py-1 bg-white border border-slate-300 rounded-md shadow-sm placeholder-slate-400
    focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
    invalid:border-red-500 invalid:text-red-600 text-base font-normal
    focus:invalid:border-red-500 focus:invalid:ring-red-500 ${props.className ? props.className : ""}`}
      value={props.text}
      pattern={props.pattern}
      onChange={(event) => props.onChange(event.target.value)}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          props.onPressEnter();
        }
      }}
    ></input>
  );
}

const InputOutput = forwardRef(_InputOutput);

export interface ContactProps {
  heyflowId: string;
}

export function Contact(props: ContactProps) {
  const { isLoading, value, storeLead, error } = useHeyFlowLeadContext();

  const [displayedName, setDisplayedName] = useState(value?.lead.name ?? "");
  const [displayedEmail, setDisplayedEmail] = useState(value?.lead.email ?? "");
  const [displayedLocation, setDisplayedLocation] = useState(value?.lead.location ?? "");

  const [isValid, setIsValid] = useState(true);

  const nameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const locationRef = useRef<HTMLInputElement>(null);

  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setDisplayedName(value?.lead.name ?? "");
  }, [value?.lead.name]);

  useEffect(() => {
    setDisplayedEmail(value?.lead.email ?? "");
  }, [value?.lead.email]);

  useEffect(() => {
    setDisplayedLocation(value?.lead.location ?? "");
  }, [value?.lead.location]);

  const startEdit = useCallback(() => {
    setIsEditing(true);
  }, []);

  const saveContact = () => {
    if (value) {
      if (!isValid) return;

      storeLead({ ...value.lead, name: displayedName, email: displayedEmail, location: displayedLocation });
    }
    setIsEditing(false);
  };

  const setValid = useCallback(() => {
    const nameIsValid = nameRef?.current?.checkValidity() ?? true;
    const emailIsValid = emailRef?.current?.checkValidity() ?? true;
    const locationIsValid = locationRef?.current?.checkValidity() ?? true;

    setIsValid(nameIsValid && emailIsValid && locationIsValid);
  }, []);

  const onChangeName = (value: string) => {
    setValid();
    setDisplayedName(value);
  };

  const onChangeEmail = (value: string) => {
    setValid();
    setDisplayedEmail(value);
  };

  const onChangeLocation = (value: string) => {
    setValid();
    setDisplayedLocation(value);
  };

  if (error) {
    return null;
  }

  return (
    <>
      <Headline title="Kontakt-Informationen" type="sub" className="mb-3 lg:mb-7" />
      {isLoading || value === undefined ? (
        <div className="flex justify-center my-8">
          <Spinner size="small"></Spinner>
        </div>
      ) : (
        <>
          <div className="flex items-center">
            <img src={user} width="20" alt="user" className="shrink-0 inline mr-3 my-2 lg:my-3 align-middle"></img>
            <InputOutput
              ref={nameRef}
              isEditing={isEditing}
              text={displayedName}
              onChange={onChangeName}
              onPressEnter={saveContact}
              className="w-full"
            />
          </div>
          <div className="flex items-center">
            <img src={email} width="20" alt="email" className="shrink-0 inline mr-3 my-2 lg:my-3 align-middle"></img>
            <InputOutput
              ref={emailRef}
              type="email"
              isEditing={false}
              text={displayedEmail}
              onChange={onChangeEmail}
              onPressEnter={saveContact}
              className="w-full"
            />
          </div>
          <div className="flex items-center">
            <img src={pin} width="20" alt="pin" className="shrink-0 inline mr-3 my-2 lg:my-3 align-middle"></img>
            <InputOutput
              ref={locationRef}
              pattern="\d\d\d\d\d"
              isEditing={isEditing}
              text={displayedLocation}
              onChange={onChangeLocation}
              onPressEnter={saveContact}
              className="w-full"
            />
          </div>
          <div
            className={`ml-1 mt-4 text-base lg:mt-10 font-light ${
              isEditing && !isValid ? "opacity-40" : "cursor-pointer"
            }`}
          >
            <img src={isEditing ? save : edit} width="16" alt="edit" className="inline mr-2 align-middle"></img>
            <span className="underline" onClick={isEditing ? saveContact : startEdit}>
              {isEditing ? "Speichern" : "Ändern"}
            </span>
          </div>
          <div className="border-t border-black mt-8 mb-5"></div>
          <div
            className="max-lg:sticky text-base font-normal bg-white"
            style={{ top: "calc(100vh - 64px)", zIndex: 100000 }}
          >
            <Submit
              heyflowId={props.heyflowId}
              cart={value.cart}
              cremationType={value.lead.lead.cremation.type}
              className="pt-2 pb-2 block"
            />
          </div>
          <div
            className="elfsight-app-068e60fc-2b38-47ea-a275-765c9384308a max-lg:mt-[-64px] max-lg:pb-4"
            data-elfsight-app-lazy
          ></div>
        </>
      )}
    </>
  );
}
