import { Link } from "react-router-dom";
import configuration from "../configuration";
import { Cart, CartItem, Lead } from "../contexts/heyFlowLead";
import { isInteger, renderPrice } from "../utils/price";
import { WordPressImage } from "./WordPressImage";
import edit from "./assets/editing.png";
import { useState } from "react";
import { Expandable } from "./Expandable";
import { usePaymentContext } from "../contexts/payment";
import { Headline } from "./Headline";

interface Props {
  cart: Cart;
  heyflowId: string;
  lead: Lead;
  expandable: boolean;
  dontShowEditLink?: boolean;
}

interface SummaryItemProps {
  cartItem: CartItem;
  omitDecimalsForIntegers: boolean;
}

function SummaryItem({ cartItem, omitDecimalsForIntegers }: SummaryItemProps) {
  return (
    <div className="flex my-3 items-center text-sm font-normal gap-6">
      <div className="shrink-0 rounded-full overflow-hidden sm:w-16 sm:h-16 w-14 h-14">
        <WordPressImage
          src={cartItem.image}
          alt="product"
          className="rounded-full scale-110"
          showThumbNail={true}
        ></WordPressImage>
      </div>
      <div className="grow flex overflow-x-hidden">
        <div className="grow overflow-x-auto">
          <span>{cartItem.name}</span>
          <div className="mt-2">
            {cartItem.variationAttributes?.map((attribute) => (
              <div key={attribute.id} className="text-sm">
                {attribute.name}: {attribute.option}
              </div>
            ))}
          </div>
        </div>
        <span className="shrink-0 ml-8">{renderPrice(cartItem.price, "EUR", omitDecimalsForIntegers)}</span>
      </div>
    </div>
  );
}

export function PurchaseSummary({ cart, heyflowId, lead, expandable, dontShowEditLink }: Props) {
  const [expanded, setExpanded] = useState(!expandable);

  const { totalPriceCents, deliveryCostCents, priceWithoutDeliveryCents } = usePaymentContext();
  const isIndividualCremation = lead.lead.cremation.type === "Einzelkremierung";

  const deliveryCost = deliveryCostCents / 100;
  const sum = totalPriceCents / 100;

  const allPricesAreInteger = cart.every((entry) => isInteger(entry.price));
  const taxRate = configuration.constants.valueAddedTaxRate;
  const tax = Math.round((100 * sum * taxRate) / (100 + taxRate)) / 100;

  const Content = () => (
    <>
      {cart.map((entry, index) => (
        <SummaryItem key={index} cartItem={entry} omitDecimalsForIntegers={allPricesAreInteger}></SummaryItem>
      ))}

      <div className="border-b border-black mt-8"></div>

      <div className="flex justify-between text-sm mt-6">
        <span>Zwischensumme</span>
        <span>{renderPrice(priceWithoutDeliveryCents / 100, "EUR", allPricesAreInteger)}</span>
      </div>
      {isIndividualCremation && (
        <div className="flex justify-between text-sm mt-3">
          <span>Versand</span>
          <span>{renderPrice(deliveryCost, "EUR", allPricesAreInteger)}</span>
        </div>
      )}
      <div className="flex justify-between text-sm mt-3">
        <span>Summe</span>
        <span className="font-bold">{renderPrice(sum, "EUR", allPricesAreInteger)}</span>
      </div>
      <div className="text-xs mt-3">inkl. {renderPrice(tax, "EUR", false)} MwSt</div>

      {dontShowEditLink !== true && (
        <Link to={`/${heyflowId}`} className="underline text-sm mt-6 block font-sm">
          <img src={edit} width="16" alt="edit" className="inline mr-2 align-middle"></img>
          <span className="underline">Auftrag bearbeiten</span>
        </Link>
      )}
    </>
  );

  return (
    <div className=" bg-neutral-100 rounded-xl lg:py-6 max-lg:py-3 lg:px-12 max-lg:px-4 max-lg:rounded-md">
      {expandable && (
        <div className="cursor-pointer flex flex-row justify-between text-sm" onClick={() => setExpanded(!expanded)}>
          {expanded ? (
            <span className="flex flex-row items-center shrink mr-2">
              <span className="shrink">Auftragsübersicht ausblenden</span>
              <div className="ml-3 border-[7px] border-solid border-b-slate-500 border-x-transparent border-t-transparent border-t-0" />
            </span>
          ) : (
            <span className="flex flex-row items-center shrink mr-2">
              <span className="shrink">Auftragsübersicht anzeigen</span>
              <div className="ml-3 border-[7px] border-solid border-t-slate-500 border-x-transparent border-b-transparent border-b-0" />
            </span>
          )}
          <span className="font-bold">{renderPrice(sum, "EUR", allPricesAreInteger)}</span>
        </div>
      )}
      {expandable ? (
        <Expandable isOpen={!expandable || expanded}>
          <div className="h-3" />
          <Content />
        </Expandable>
      ) : (
        <>
          <Headline title="Auftragszusammenfassung" type="sub" className="pb-4" />
          <Content />
        </>
      )}
    </div>
  );
}
