import tickMark from "../components/assets/tick-circle.svg";

interface Props {
  title: string;
  type: "main" | "sub";
  showTickMark?: boolean;
  className?: string;
}

export function Headline({ title, type, showTickMark, className }: Props) {
  let fontClass;
  switch (type) {
    case "main":
      fontClass = "text-3xl md:text-4xl leading-relaxed md:leading-relaxed ";
      break;
    case "sub":
      fontClass = "text-3xl leading-snug md:leading-snug";
      break;
  }

  return (
    <div className={`font-normal font-shadows text-green flex gap-2 items-start ${fontClass} ${className ?? ""}`}>
      {showTickMark === true && <img style={{ marginTop: 9 }} src={tickMark} width="30" />}
      <span>{title}</span>
    </div>
  );
}
