import { useState } from "react";
import { MEASURES } from "./FancyTextInput";
import { DropdownIcon } from "./DropdownIcon";

interface Props<V extends string> {
  options: readonly { text: string; value: V }[];
  value: V | undefined;
  defaultValue?: V;
  smallDropdownItem?: boolean;
  hasError: boolean;
  setValue: (value: V) => void;
}

export function SelectBox<V extends string>({
  options,
  value,
  defaultValue,
  smallDropdownItem,
  hasError,
  setValue,
}: Props<V>) {
  const [hasFocus, setHasFocus] = useState(false);

  return (
    <div
      className={`relative items-center border-solid text-slate-900 text-sm rounded-md w-full p-2.5 ${
        hasError
          ? "border-red-600 hover:border-red-600"
          : hasFocus
          ? "border-sky-600 hover:border-sky-600"
          : "border-slate-400 hover:border-slate-950 border"
      }`}
      style={{
        borderWidth:
          hasFocus || hasError
            ? `${MEASURES.borderFocusWidth}${MEASURES.unit}`
            : `${MEASURES.borderWidth}${MEASURES.unit}`,
        height: `${MEASURES.fontSizeMeasure * MEASURES.labelHeightMultiplier + 2 * MEASURES.inputPaddingY}${
          MEASURES.fontSizeUnit
        }`,
        padding: hasFocus ? `${MEASURES.borderWidth}${MEASURES.unit}` : `${MEASURES.borderFocusWidth}${MEASURES.unit}`,
      }}
    >
      <select
        className="appearance-none outline-none grow h-full w-full cursor-pointer bg-white"
        value={value ?? defaultValue}
        onChange={(event) => setValue(event.target.value as V)}
        onFocus={() => {
          setHasFocus(true);
        }}
        onBlur={() => {
          setHasFocus(false);
        }}
        style={{
          paddingLeft: `${MEASURES.inputPaddingX - MEASURES.borderFocusWidth - MEASURES.borderWidth}${MEASURES.unit}`,
        }}
      >
        {options.map(({ text, value }) => (
          <option key={value} value={value}>
            {text}
          </option>
        ))}
      </select>
      <div className="absolute h-full right-0 top-0 flex items-center pointer-events-none">
        <DropdownIcon small={smallDropdownItem} />
      </div>
    </div>
  );
}
