export const FORCE_TEST_MODE = new URLSearchParams(window.location.search).get("test") === "true";

const configuration = {
  api: {
    url: "https://idif4mmw63.execute-api.eu-central-1.amazonaws.com",
  },
  netlify: {
    url: "https://angebot.animaltree.de",
  },
  shopweiterleitung: {
    url: "https://animaltree.de/shopweiterleitung/",
  },
  animaltree: {
    url: "https://animaltree.de",
    urnShop: "https://haustierurnen.de/",
  },
  constants: {
    valueAddedTaxRate: 19,
  },
  test: {
    mockLead: false,
  },
  stripe: {
    publicApiKeyTest: "pk_test_w7fbFmRNZ8XM70M5dvHPImzI00VOJNgumN",
    publicApiKeyProduction: "pk_live_CVPCOV6UbdIaASjbQP2eXHMc00i4nsdcuH",
    testMode: !import.meta.env.PROD || FORCE_TEST_MODE, // otherwise production
    useCustomerSessions: false, // if true, allows users to store their payment methods
  },
  business: { name: "Animaltree" },
  deliveryCostInCents: {
    dhl: 1000,
    pickup: 0,
  },
};

export default configuration;
