import step1 from "./assets/Telefon-gruen.svg";
import step2 from "./assets/Rueckfuehrung-scaled-1.png";
import step3 from "./assets/Kremierung-Illu.png";
import step4 from "./assets/zum-vitalen-Baum-gruen_neu.svg";
import { Headline } from "./Headline";

interface NextStepBoxProps {
  stepNumber: number;
  title: string;
  description: string;
  icon: string;
  parity: "left" | "right";
  iconsize: [number, number];
  showDescription: boolean;
}

function NextStepBox(props: NextStepBoxProps) {
  return (
    <div className="w-full md:w-1/2 shrink-0 grow-0 relative">
      <div
        className={`${
          props.parity === "left" ? "md:mr-5" : "md:ml-5"
        } bg-neutral-100 rounded-lg items-start h-full my-2 md:my-0 lg:items-center ${props.showDescription ? "p-8 lg:flex" : "p-4 flex"}`}
      >
        <img
          src={props.icon}
          alt="icon"
          className={`shrink-0 flex justify-center ${props.showDescription ? "max-lg:float-right w-24 md:w-28 lg:order-2" : "w-20 md:w-24 order-2"} grow-0 pl-4`}
          width={Math.round(props.iconsize[0] * (props.showDescription ? 1 : 0.7))}
          height={Math.round(props.iconsize[0] * (props.showDescription ? 1 : 0.7))}
        />
        <div className={`grow pr-4 text-base ${props.showDescription ? "lg:order-1" : "order-1"}`}>
          <div className="text-gray-600 font-medium mb-2">Schritt {props.stepNumber}</div>
          <div className={`${props.showDescription ? "mb-6 font-semibold" : "text-sm"}`}>{props.title}</div>
          {props.showDescription && <div>{props.description}</div>}
        </div>
      </div>
    </div>
  );
}

interface NextStepProps {
  showDescription: boolean;
}

export function NextSteps({ showDescription }: NextStepProps) {
  return (
    <>
      <Headline title="So geht es nach der Buchung weiter" type="sub" className="mt-3 lg:mt-10" />
      <div className="flex flex-col md:flex-row relative items-stretch md:my-10">
        <NextStepBox
          stepNumber={1}
          title="Wir rufen dich an"
          description="Dein persönlicher Ansprechpartner bei Animal Tree ruft dich an, um alle weiteren Schritte direkt mit dir zu besprechen."
          icon={step1}
          parity="left"
          iconsize={[143, 91]}
          showDescription={showDescription}
        ></NextStepBox>
        <NextStepBox
          stepNumber={2}
          title="Wir holen dein Tier ab / Du bringst dein Tier"
          description="Die Abholung ist meistens am Tag selbst oder am Folgetag möglich. Du kannst dein Tier natürlich auch selbst zum Standort in deiner Nähe bringen."
          icon={step2}
          parity="right"
          iconsize={[130, 81]}
          showDescription={showDescription}
        ></NextStepBox>
      </div>
      <div className="flex flex-col md:flex-row relative items-stretch">
        <NextStepBox
          stepNumber={3}
          title="Einäscherung deines Lieblings"
          description="Innerhalb von 7 Tagen nach der Abholung findet die Kremierung statt."
          icon={step3}
          parity="left"
          iconsize={[80, 80]}
          showDescription={showDescription}
        ></NextStepBox>
        <NextStepBox
          stepNumber={4}
          title="Rückführung der Asche"
          description="Nach ca. 14 Tagen senden wir dir die Asche zusammen mit der Urne deiner Wahl zurück. Alternativ kannst du die Urne auch wieder am Standort abholen."
          icon={step4}
          parity="right"
          iconsize={[130, 120]}
          showDescription={showDescription}
        ></NextStepBox>
      </div>
    </>
  );
}
