import { Link } from "react-router-dom";
import { CartItemType, useHeyFlowLeadContext } from "../contexts/heyFlowLead";
import { productShops, removableProduct, simpleProducts } from "../utils/productTypes";
import { Button } from "./Button";
import { WordPressImage } from "./WordPressImage";
import { ProductVariationAttribute } from "../contexts/products";
import { renderPrice } from "../utils/price";

export interface ProductListingProps {
  heyflowId: string;
  title: string;
  price?: number;
  variationAttributes?: ProductVariationAttribute[];
  shortDescription: string;
  image: string;
  className?: string;
  onClick: () => void;
  type:
    | { type: "placeholder"; cartItemType: CartItemType }
    | { type: "cart"; cartItemIndex: number; cartItemType: CartItemType; productId: number; variationId?: number };
}

export function ProductListing(props: ProductListingProps) {
  const { addCartItem, removeCartItem, changeCartItem, standardProductDefinitions } = useHeyFlowLeadContext();

  if (!standardProductDefinitions) {
    return null;
  }

  const { type } = props;

  const { placeholderProducts, transportProducts } = standardProductDefinitions;
  const placeholderProduct = placeholderProducts[type.cartItemType];

  let description = props.shortDescription;
  if (!description) {
    if (props.type.type === "cart") {
      if (props.type.variationId === 41105) {
        description = "<p>Ich fahre selber zum Standort</p";
      } else if (props.type.variationId === 41104) {
        description = "<p>Abholung bei dir oder deinem Tierarzt</p";
      }
    }
  }

  const isSimpleProduct = simpleProducts.indexOf(type.cartItemType) !== -1;
  const allowDescription =
    type.cartItemType !== "urn" && type.cartItemType !== "inscription" && type.cartItemType !== "jewelry";

  const allowVariantDetails = type.cartItemType === "urn" || type.cartItemType === "jewelry";

  return (
    <div
      className={`rounded-xl bg-neutral-100 overflow-hidden flex justify-start py-4 px-4 md:py-6 md:px-8 text-sm md:text-base mt-3 lg:mt-5 first:mt-3 ${
        props.className ? props.className : ""
      }`}
    >
      <div className="shrink-0 pr-4 md:pr-8">
        <div className="w-16 md:w-24 bg-white h-16 md:h-24 rounded-full overflow-hidden flex justify-center items-center">
          <WordPressImage
            src={props.image}
            alt="product"
            className="rounded-full scale-110"
            showThumbNail={true}
          ></WordPressImage>
        </div>
      </div>
      <div className="grow">
        <div className="flex justify-between">
          <span className="font-semibold md:font-normal">{props.title}</span>
          {props.price !== undefined && (
            <span className={`grow-0 shrink-0 ml-4 ${props.type.type === "placeholder" ? "text-neutral-400" : ""}`}>
              {renderPrice(props.price, "EUR", true)}
            </span>
          )}
        </div>
        {props.variationAttributes && allowVariantDetails && (
          <div className="mt-2">
            {props.variationAttributes.map((attribute) => (
              <div key={attribute.id} className="text-sm">
                {attribute.name}: {attribute.option}
              </div>
            ))}
          </div>
        )}
        {description && allowDescription && (
          <div className="text-sm mt-4" dangerouslySetInnerHTML={{ __html: description }}></div>
        )}
        {type.type === "cart" ? (
          <div className="flex justify-between mt-5 items-center">
            {removableProduct[type.cartItemType] && (
              <Button
                className="text-sm"
                caption={"- entfernen"}
                onClick={() => {
                  removeCartItem(type.cartItemIndex);
                }}
                fullWidth={false}
                primary={false}
              ></Button>
            )}
            {type.cartItemType === "transport" ? (
              <Button
                className="text-sm"
                caption={type.variationId === 41105 ? "Ändern zur Abholung" : "Ändern zur Abgabe"}
                onClick={() => {
                  changeCartItem(
                    type.variationId === 41105 ? transportProducts.pickUp : transportProducts.dropOff,
                    Number(type.cartItemIndex)
                  );
                }}
                fullWidth={false}
                primary={true}
              ></Button>
            ) : type.cartItemType === "urn" ? (
              <Link
                to={productShops["urn"]?.(props.heyflowId) ?? ""}
                className="mt-5 items-center justify-center text-center px-8 rounded-xl font-normal bg-neutral-200 hover:bg-neutral-300 transition-colors duration-300 inline-flex text-sm py-3"
              >
                Auswahl ändern
              </Link>
            ) : (
              !isSimpleProduct && (
                <Link to={`/${props.heyflowId}/cart/${type.cartItemIndex}`} className="underline text-sm">
                  Mehr Infos
                </Link>
              )
            )}
          </div>
        ) : isSimpleProduct ? (
          <button
            type="button"
            className="mt-5 items-center justify-center text-center px-8 rounded-xl font-normal bg-green hover:bg-greenhover transition-colors duration-300 inline-flex text-sm py-3 text-white"
            onClick={() => {
              addCartItem(placeholderProduct);
            }}
          >
            Hinzufügen
          </button>
        ) : (
          <Link
            to={productShops[type.cartItemType]?.(props.heyflowId) ?? ""}
            className="mt-5 items-center justify-center text-center px-8 rounded-xl font-normal bg-green hover:bg-greenhover transition-colors duration-300 inline-flex text-sm py-3 text-white"
          >
            Hinzufügen
          </Link>
        )}
      </div>
    </div>
  );
}
