import { useState } from "react";
import { MEASURES } from "./FancyTextInput";
import { DropdownIcon } from "./DropdownIcon";

export const COUNTRY_CODES = [
  { code: "+49", text: "+49 Deutschland", flag: "🇩🇪" },
  { code: "+31", text: "+31 Niederlande", flag: "🇳🇱" },
  { code: "+32", text: "+32 Belgien", flag: "🇧🇪" },
  { code: "+352", text: "+352 Luxemburg", flag: "🇱🇺" },
  { code: "+33", text: "+33 Frankreich", flag: "🇫🇷" },
  { code: "+41", text: "+41 Schweiz", flag: "🇨🇭" },
  { code: "+43", text: "+43 Österreich", flag: "🇦🇹" },
  { code: "+39", text: "+39 Italien", flag: "🇮🇹" },
  { code: "+420", text: "+420 Tschechien", flag: "🇨🇿" },
  { code: "+48", text: " +48 Polen", flag: "🇵🇱" },
  { code: "+34", text: "+34 Spanien", flag: "🇪🇸" },
] as const;

export type CountryCode = (typeof COUNTRY_CODES)[number]["code"];

export const DEFAULT_COUNTRY_CODE: CountryCode = "+49";

interface Props {
  countryCode: CountryCode | "other" | undefined;
  number: string | undefined;
  setCountryCode: (countryCode: CountryCode | "other") => void;
  setNumber: (number: string) => void;
  id: string;
  hasError: boolean;
  isOptional?: boolean;
}

export function PhoneInput({ id, countryCode, number, hasError, isOptional, setCountryCode, setNumber }: Props) {
  const [hasFocus, setHasFocus] = useState(false);

  const selectedCountry =
    countryCode === "other"
      ? "other"
      : COUNTRY_CODES.find((country) => country.code === (countryCode ?? DEFAULT_COUNTRY_CODE))!;

  return (
    <>
      <div
        className={`border-solid text-slate-900 text-sm rounded-md w-full p-2.5 ${
          hasError
            ? "border-red-600 hover:border-red-600"
            : hasFocus
            ? "border-sky-600 hover:border-sky-600"
            : "border-slate-400 hover:border-slate-950 border"
        }`}
        style={{
          borderWidth:
            hasFocus || hasError
              ? `${MEASURES.borderFocusWidth}${MEASURES.unit}`
              : `${MEASURES.borderWidth}${MEASURES.unit}`,
          height: `${MEASURES.fontSizeMeasure * MEASURES.labelHeightMultiplier + 2 * MEASURES.inputPaddingY}${
            MEASURES.fontSizeUnit
          }`,
          padding: hasFocus
            ? `${MEASURES.borderWidth}${MEASURES.unit}`
            : `${MEASURES.borderFocusWidth}${MEASURES.unit}`,
        }}
      >
        <div className="flex h-full items-center px-2">
          <div className="relative h-full">
            <select
              id={`${id}-country-code`}
              className="cursor-pointer absolute w-full h-full top-0 left-0 appearance-none focus-visible:outline-none bg-white"
              value={countryCode ?? DEFAULT_COUNTRY_CODE}
              onChange={(e) => setCountryCode(e.target.value as CountryCode | "other")}
            >
              {COUNTRY_CODES.map(({ code, text }) => (
                <option key={code} value={code}>
                  {text}
                </option>
              ))}
              <option value={"other"}>Andere</option>
            </select>
            <div className="absolute w-full h-full top-0 left-0 pointer-events-none bg-white flex text-nowrap items-center ">
              <span className="grow">
                <span className="text-lg align-middle">{selectedCountry === "other" ? "" : selectedCountry.flag}</span>
                <span className="align-middle pl-1">
                  {selectedCountry === "other" ? "" : countryCode ?? DEFAULT_COUNTRY_CODE}
                </span>
              </span>
              <DropdownIcon />
            </div>
            <div className="invisible h-full overflow-y-hidden">
              {COUNTRY_CODES.map(({ code, flag }) => (
                <div className="flex text-nowrap" key={code}>
                  <span className="text-lg align-middle">{flag}</span>
                  <span className="align-middle pl-1">{code}</span>
                  <DropdownIcon />
                </div>
              ))}
            </div>
          </div>
          <input
            id={`${id}-number`}
            data-1p-ignore
            type="tel"
            className="grow h-full pl-3 outline-none text-sm border-none box-content bg-transparent m-0 block min-w-0 w-full placeholder:text-slate-600"
            onFocus={() => {
              setHasFocus(true);
            }}
            onBlur={() => {
              setHasFocus(false);
            }}
            value={number ?? ""}
            onChange={(e) => {
              setNumber(e.target.value);
            }}
            placeholder={isOptional === true ? "Telefonnummer (optional)" : "Telefonnummer"}
          />
        </div>
      </div>
    </>
  );
}
