import { Lead } from "../contexts/heyFlowLead";

import assistant from "./assets/maike.jpg";

interface Props {
  lead: Lead;
}

export function PurchaseAssistant({ lead }: Props) {
  const AssistantStatus = () => (
    <div className="text-sm mt-3 flex items-start">
      <div className="flex items-center flex-row">
        <div className="w-2 h-2 rounded-full bg-green"></div>
        <span className="pl-2 pr-5">Online</span>
      </div>
      {lead.region && (
        <span className="">
          Betreuender Standort: <span className="font-bold">{lead.region}</span>
        </span>
      )}
    </div>
  );

  return (
    <div className="bg-neutral-100 rounded-xl py-5 px-5  text-sm font-normal">
      <div className="flex flex-row items-start">
        <img src={assistant} width="80" alt="auto" className="inline mr-4 align-middle shrink-0 rounded-full"></img>
        <div>
          <span className="text-sm font-normal">
            Deine Ansprechpartnerin meldet sich im Anschluss an deinen Auftrag bei dir, um alles weitere zu besprechen.
          </span>
          <div className="max-lg:hidden">
            <AssistantStatus />
          </div>
        </div>
      </div>
      <div className="lg:hidden">
        <AssistantStatus />
      </div>
    </div>
  );
}
