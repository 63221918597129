import { usePurchaseContext } from "../../contexts/purchase";
import { createAddressString } from "../../utils/address";
import { Checkbox } from "../Checkbox";
import { CountrySelect } from "../CountrySelect";
import { Expandable } from "../Expandable";
import { FancyFormTextInput } from "../FancyTextInput";
import { FormErrorMessage } from "../FormErrorMessage";
import { PhoneInput } from "../PhoneInput";

import pin from "../assets/pin.png";

export function PurchasePickupAddress() {
  const {
    purchaseInformation,
    errors,
    updateField,
    onPickupAddressCountryChange,
    pickupAddressPredictions,
    selectPickupAddressPrediction,
    cancelPickupAddressPrediction,
  } = usePurchaseContext();

  let invoiceAddress: string | undefined;
  let pickupAddress: string | undefined;

  if (purchaseInformation !== undefined) {
    const {
      invoiceAddressStreet,
      invoiceAddressZipCode,
      invoiceAddressCity,
      invoiceAddressCountry,
      pickupAddressStreet,
      pickupAddressZipCode,
      pickupAddressCity,
      pickupAddressCountry,
    } = purchaseInformation;

    invoiceAddress = createAddressString(
      "",
      invoiceAddressStreet,
      invoiceAddressZipCode,
      invoiceAddressCity,
      invoiceAddressCountry
    );

    pickupAddress = createAddressString(
      "",
      pickupAddressStreet,
      pickupAddressZipCode,
      pickupAddressCity,
      pickupAddressCountry
    );
  }

  return (
    <>
      <div className="font-bold mt-7 mb-0">Abholung deines Tieres</div>
      <div className="flex mb-2 items-center">
        <img src={pin} width="20" alt="pin" className="shrink-0 inline mr-3 my-2 lg:my-3 align-middle"></img>
        <div className="text-sm truncate">
          {(purchaseInformation?.hasExtraPickupAddress ? pickupAddress : invoiceAddress) ?? ""}
        </div>
      </div>
      <Checkbox
        formKey="hasExtraPickupAddress"
        formValue={purchaseInformation}
        setValue={updateField}
        caption="Die Abholadresse weicht von der Rechnungsadresse ab"
        className="py-3 px-4 text-sm rounded-md bg-neutral-200"
      />

      <Expandable isOpen={purchaseInformation?.hasExtraPickupAddress ?? false}>
        <div className="pt-3" />
        <CountrySelect
          country={purchaseInformation?.pickupAddressCountry}
          setCountry={(country) => {
            updateField("pickupAddressCountry", country);
            onPickupAddressCountryChange(country);
          }}
          hasError={errors.pickupAddressCountry !== undefined}
        />
        <div className="pt-3" />
        <FancyFormTextInput
          label="Tierarztpraxis / Name"
          type="text"
          formKey="pickupAddressPracticeName"
          formValue={purchaseInformation}
          setFormValue={updateField}
          errors={errors}
        />
        <div className="pt-3" />
        <FancyFormTextInput
          label="Straße und Hausnummer"
          type="text"
          formKey="pickupAddressStreet"
          formValue={purchaseInformation}
          setFormValue={updateField}
          errors={errors}
          mapsPredictions={pickupAddressPredictions}
          cancelPredictions={cancelPickupAddressPrediction}
          selectPrediction={selectPickupAddressPrediction}
        />
        <div className="flex gap-4 pt-3">
          <FancyFormTextInput
            label="Postleitzahl"
            type="text"
            formKey="pickupAddressZipCode"
            formValue={purchaseInformation}
            setFormValue={updateField}
            errors={errors}
          />
          <FancyFormTextInput
            label="Stadt"
            type="text"
            formKey="pickupAddressCity"
            formValue={purchaseInformation}
            setFormValue={updateField}
            errors={errors}
          />
        </div>
        <div className="pt-3" />
        <PhoneInput
          countryCode={purchaseInformation?.pickupAddressPhoneCountryCode}
          number={purchaseInformation?.pickupAddressPhoneNumber}
          setCountryCode={(countryCode) => updateField("pickupAddressPhoneCountryCode", countryCode)}
          setNumber={(number) => updateField("pickupAddressPhoneNumber", number)}
          id="pickup-address-phone"
          hasError={errors.pickupAddressPhoneNumber !== undefined}
          isOptional={true}
        />
        <FormErrorMessage
          formKeys={[
            "pickupAddressCountry",
            "pickupAddressPracticeName",
            "pickupAddressStreet",
            "pickupAddressZipCode",
            "pickupAddressCity",
            "pickupAddressPhoneCountryCode",
            "pickupAddressPhoneNumber",
          ]}
        />
      </Expandable>
    </>
  );
}
