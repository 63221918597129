import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { StripeComplete } from "../components/StripeComplete";
import { useStripe } from "@stripe/react-stripe-js";
import { usePaymentContext } from "../contexts/payment";
import { usePurchaseContext } from "../contexts/purchase";

import { PurchasePageLayout } from "../components/PurchasePageLayout";

declare global {
  interface Window {
    dataLayer: object[];
  }
}

export function PurchaseComplete() {
  const { heyflowId } = useParams();
  const stripe = useStripe();

  const { purchaseInformation } = usePurchaseContext();
  const {
    fetchExistingPaymentIntent,
    paymentIntentResult,
    finalHeadline,
    paymentStatus,
    asyncPaymentMethodAndOrderId,
  } = usePaymentContext();

  const asyncPaymentMethodAndOrderIdFetch = asyncPaymentMethodAndOrderId.fetch;
  useEffect(() => {
    if (!stripe || !heyflowId) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");

    if (!clientSecret) {
      return;
    }

    fetchExistingPaymentIntent(stripe, clientSecret);
    asyncPaymentMethodAndOrderIdFetch(heyflowId, clientSecret);
  }, [fetchExistingPaymentIntent, stripe, heyflowId, asyncPaymentMethodAndOrderIdFetch]);

  // originally I used the orderId here, but it could be slow to load
  // and then the purchase_completed event might not be sent before the user
  // closes the tab
  const orderId = heyflowId;
  const amount = paymentIntentResult?.paymentIntent?.amount;

  useEffect(() => {
    if (amount === undefined || !orderId) return;
    window.dataLayer?.push({ event: "purchase_completed", orderNumber: orderId, convValue: amount / 100 });
  }, [amount, orderId]);

  const refetchPendingInterval = useRef<number | undefined>(undefined);
  useEffect(() => {
    const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");
    if (!clientSecret || !stripe) {
      return;
    }

    if (paymentStatus === "pending") {
      refetchPendingInterval.current = setInterval(() => {
        fetchExistingPaymentIntent(stripe, clientSecret);
      }, 5000);
    }

    return () => {
      if (refetchPendingInterval.current) {
        clearInterval(refetchPendingInterval.current);
        refetchPendingInterval.current = undefined;
      }
    };
  }, [fetchExistingPaymentIntent, paymentStatus, stripe]);

  return (
    <PurchasePageLayout
      isLoadingExtraData={purchaseInformation === undefined || paymentIntentResult === undefined}
      loadingMessage="Deine Zahlung wird geladen"
      createHeadline={() => finalHeadline!}
      showHeaderTickMark={paymentStatus === "success"}
      dontShowPurchaseEditLink={true}
      dontShowNextStepsAndTestimonials={true}
      renderChild={({ lead, heyflowId }) => (
        <StripeComplete
          lead={lead}
          heyflowId={heyflowId}
          purchaseInformation={purchaseInformation!}
          paymentIntent={paymentIntentResult!.paymentIntent}
          paymentStatus={paymentStatus}
          paymentMethodPreview={asyncPaymentMethodAndOrderId.value?.paymentMethodPreview}
          paymentMethodPreviewIsLoading={asyncPaymentMethodAndOrderId.isLoading}
        />
      )}
    />
  );
}
