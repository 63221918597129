import { ConfirmationToken } from "@stripe/stripe-js";
import configuration from "../configuration";
import { Cart, CartItem, Lead, StandardProducts } from "../contexts/heyFlowLead";
import { PaymentIntentResponse, StripeCustomerSessionInfo } from "../contexts/payment";
import { Product, ProductAndVariation, ProductVariation } from "../contexts/products";
import { PurchaseInformation } from "../contexts/purchase";
import { jsonBodyFetch, queryFetch } from "./fetch";
import { captureMessage } from "@sentry/react";

export async function fetchLead(heyflowId: string): Promise<{
  lead: Lead;
  cart: Cart;
}> {
  if (configuration.test.mockLead) {
    return {
      lead: {
        email: "johann@gmail.com",
        lead: {
          animalType: "dein Hund",
          concern: "verstorben",
          cremation: { type: "Einzelkremierung", urn: "keramik Urne" },
          pawPrint: "Ja",
          pickup: "Abholung",
          weight: "15,1 - 25 kg",
        },
        location: "10405",
        name: "Johann König",
        offerId: "39429",
        region: "Düsseldorf",
      },
      cart: [],
    };
  }

  const result = await queryFetch<undefined, { lead: Lead; cart: Cart }>(`/lead/${heyflowId}`, "get", undefined);
  result.cart = result.cart.filter((cartItem) => cartItem.dontShowInFrontEnd !== true);
  return result;
}

export async function fetchProduct(heyflowId: string, productId: string) {
  return queryFetch<undefined, Product>(`/product/${heyflowId}/${productId}`, "get", undefined);
}

export async function fetchStandardProducts(heyflowId: string) {
  return queryFetch<undefined, StandardProducts>(`/standardproducts/${heyflowId}`, "get", undefined);
}

export type ProductType = "urnen" | "beschriftung" | "schmuck";

export async function fetchProductList(heyflowId: string, productType: ProductType) {
  return queryFetch<undefined, Product[]>(`/${productType}/${heyflowId}`, "get", undefined);
}

export async function fetchProductVariations(heyflowId: string, productId: string) {
  return queryFetch<undefined, ProductVariation[]>(`/variations/${heyflowId}/${productId}`, "get", undefined);
}

export async function fetchProductAndVariations(heyflowId: string, productId: string) {
  return queryFetch<undefined, ProductAndVariation>(`/product_variations/${heyflowId}/${productId}`, "get", undefined);
}

export async function fetchPurchaseForm(heyflowId: string) {
  return queryFetch<undefined, PurchaseInformation | null>(`/purchaseform/${heyflowId}`, "get", undefined);
}

export async function fetchPaymentMethodPreviewAndOrderId(heyflowId: string, paymentIntentClientSecret: string) {
  const paymentPreviewJson = await queryFetch<undefined, { paymentMethodPreview: string; orderId?: string }>(
    `/stripe/payment-method-preview/${heyflowId}/${paymentIntentClientSecret}`,
    "get",
    undefined
  );

  return {
    paymentMethodPreview: JSON.parse(paymentPreviewJson.paymentMethodPreview) as ConfirmationToken.PaymentMethodPreview,
    orderId: paymentPreviewJson.orderId,
  };
}

export async function fetchNewPaymentIntent(
  heyflowId: string,
  cartHash: string,
  totalPriceCents: number,
  confirmationToken: ConfirmationToken,
  useCustomerSession: boolean
) {
  return await jsonBodyFetch<
    {
      cartHash: string;
      totalPriceCents: number;
      paymentMethodPreview: string;
      useCustomerSession: boolean;
    },
    PaymentIntentResponse
  >(`/stripe/payment-intent/${heyflowId}`, "post", {
    cartHash,
    totalPriceCents,
    paymentMethodPreview: JSON.stringify(confirmationToken.payment_method_preview),
    useCustomerSession,
    ...(configuration.stripe.testMode ? { test: true } : undefined),
  });
}

export async function retrieveStripeCustomerSession(heyflowId: string, forceRefresh?: boolean) {
  return jsonBodyFetch<{ forceRefresh?: boolean }, StripeCustomerSessionInfo>(
    `/stripe/customer-session/${heyflowId}`,
    "post",
    {
      forceRefresh,
      ...(configuration.stripe.testMode ? { test: true } : undefined),
    }
  );
}

export async function normalizeCart(heyflowId: string) {
  return queryFetch<undefined, { cart?: Cart }>(`/cart-normalize/${heyflowId}`, "put", undefined);
}

export async function storePurchaseForm(heyflowId: string, purchaseForm: PurchaseInformation) {
  return jsonBodyFetch<PurchaseInformation, void>(`/purchaseform/${heyflowId}`, "post", purchaseForm);
}

export async function putInitialLead(formContent: unknown) {
  try {
    const response = await fetch(`${configuration.api.url}/heyflow`, {
      headers: { "Content-type": "application/json", Authorization: `Basic aGV5ZmxvdzphQzRjY1Y0Z1hoalJUSkE3WUc4TA==` },
      method: "post",
      body: JSON.stringify({ fields: formContent }),
    });

    if (response.status >= 400) {
      try {
        captureMessage(`Error response: ${await response.json()}`);
      } catch {
        /* */
      }
      throw new Error(`Response status is ${response.status}`);
    }
  } catch (error: unknown) {
    let message = "Unknown error";
    if (error instanceof Error) message = error.message;
    captureMessage(`putInitialLead fetch failed: ${error}, ${message}`);
    throw error;
  }
}

export async function storeContacts(heyflowId: string, name: string, email: string, location: string) {
  return jsonBodyFetch<{ name: string; email: string; location: string }, Lead>(`/contact/${heyflowId}`, "put", {
    name,
    email,
    location,
  });
}

export interface MapsAutocompleteResult {
  street?: string;
  streetNumber?: string;
  zipCode?: string;
  city?: string;
}

export interface MatchedSubstring {
  length: number;
  offset: number;
}

export interface PlacePrediction {
  description: string;
  matchedSubstrings: { length: number; offset: number }[];
  placeId: string;
}

export async function fetchPlaceAutocomplete(text: string, region: string) {
  const result = await queryFetch<{ text: string; region: string }, PlacePrediction[]>(`/place-autocomplete`, "get", {
    text,
    region,
  });

  return result ?? {};
}

export async function fetchPlaceDetails(placeId: string) {
  const result = await queryFetch<undefined, MapsAutocompleteResult>(`/place/${placeId}`, "get", undefined);

  return result ?? {};
}

export async function storeCart(heyflowId: string, cart: CartItem[]) {
  return jsonBodyFetch<{ cart: CartItem[] }, void>(`/cart/${heyflowId}`, "put", {
    cart,
  });
}
