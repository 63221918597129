interface Props<T extends string, S extends Record<T, boolean> | undefined> {
  caption: string;
  formKey: T;
  formValue: S;
  setValue: (key: T, isChecked: boolean) => void;
  className: string;
  checkedClassName?: string;
  uncheckedClassName?: string;
}

export function Checkbox<T extends string, S extends Record<T, boolean> | undefined>({
  formKey,
  caption,
  setValue,
  formValue,
  className,
  checkedClassName = "",
  uncheckedClassName = "",
}: Props<T, S>) {
  return (
    <label
      className={`flex items-center cursor-pointer gap-3 select-none ${className} ${
        formValue?.[formKey] ? checkedClassName : uncheckedClassName
      }`}
    >
      <input
        className="appearance-none before:content-[''] w-5 h-5 bg-white rounded-sm border border-solid border-slate-900 before:w-3 before:h-3 before:bg-green grid place-content-center before:scale-0 before:checked:scale-100 before:transition-all shrink-0"
        type="checkbox"
        checked={formValue?.[formKey] ?? false}
        onChange={(e) => setValue(formKey, e.target.checked)}
      />
      {caption}
    </label>
  );
}
