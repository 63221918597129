import { StripeElementsOptions } from "@stripe/stripe-js";

export function getStripeOptions(
  totalPriceCents: number,
  customerSessionClientSecret: string | undefined
): StripeElementsOptions {
  return {
    mode: "payment",
    amount: totalPriceCents,
    currency: "eur",
    ...(customerSessionClientSecret ? { customerSessionClientSecret } : undefined),
    paymentMethodCreation: "manual",
    appearance: {
      theme: "stripe",
      variables: {
        fontFamily: "Poppins",
        fontSizeBase: "14px",
        fontSizeXs: "14px",
        fontSizeSm: "14px",
        borderRadius: "0.35rem",
        //colorPrimary: "#a3c95c",
        colorBackground: "white",
        colorText: "rgb(23, 23, 23)",
      },
    },
    loader: "auto",
    fonts: [{ cssSrc: "https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600&display=swap" }],
    locale: "de",
  };
}
