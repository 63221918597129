import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { PurchaseDetailsCheck } from "../components/PurchaseDetailsCheck";
import { usePaymentContext } from "../contexts/payment";
import { PurchasePageLayout } from "../components/PurchasePageLayout";

export function CheckPurchase() {
  const { heyflowId } = useParams();
  const { ensureCustomerSession } = usePaymentContext();

  useEffect(() => {
    if (!heyflowId) return;
    ensureCustomerSession(heyflowId);
  }, [ensureCustomerSession, heyflowId]);

  return (
    <PurchasePageLayout
      isLoadingExtraData={false}
      loadingMessage="Dein Angebot wird geladen"
      createHeadline={() => "Überprüfen und bezahlen"}
      renderChild={({ lead, heyflowId }) => <PurchaseDetailsCheck lead={lead} heyflowId={heyflowId} />}
    />
  );
}
