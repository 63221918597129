import { Link } from "react-router-dom";
import { usePurchaseContext } from "../../contexts/purchase";
import { FancyFormTextInput } from "../FancyTextInput";
import lamp from "../assets/lamp.png";
import { Lead } from "../../contexts/heyFlowLead";

interface Props {
  heyflowId: string;
  lead: Lead;
  requiresInscription: boolean;
  allowInscriptionInput: boolean;
}

export function PurchaseUrn({ heyflowId, lead, requiresInscription, allowInscriptionInput }: Props) {
  const { purchaseInformation, errors, updateField } = usePurchaseContext();

  if (lead.lead.cremation.type === "Sammelkremierung") {
    return null;
  }

  return (
    <>
      <div className="font-bold mt-7 mb-3">Beschriftung der Urne</div>
      {requiresInscription && (
        <div className="flex items-center justify-start mt-5 mb-3 text-xs">
          <img src={lamp} width="20" alt="lamp"></img>
          <span className="text-neutral-500 font-medium pl-1">
            Du hast keine Beschriftung ausgewählt.{" "}
            <Link to={`/${heyflowId}/products/beschriftung?back=purchase`} className="underline">
              Hier
            </Link>{" "}
            kannst du eine Beschriftung hinzufügen.
          </span>
        </div>
      )}
      {allowInscriptionInput && (
        <FancyFormTextInput
          label="Beschriftung der Urne oder Andenken"
          type="textarea"
          formKey="inscription"
          formValue={purchaseInformation}
          setFormValue={updateField}
          errors={errors}
          textAreaInitialRows={1}
        />
      )}
    </>
  );
}
