import { COUNTRIES, Country } from "../components/CountrySelect";
import { COUNTRY_CODES, CountryCode } from "../components/PhoneInput";
import { AnimalReturnType, AnimalStage, AnimalType } from "../contexts/purchase";

const EMAIL_REGEXP = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export function validateEmail(input: string): string {
  const normalizedInput = input.trim().toLowerCase();

  if (normalizedInput === "") {
    throw new Error("Bitte E-Mail-Adresse angeben");
  }

  if (EMAIL_REGEXP.test(normalizedInput)) {
    return normalizedInput;
  }

  throw new Error("Bitte gültige E-Mail-Adresse angeben");
}

export function validateCountry(input: Country): Country {
  const normalizedInput = input.trim();

  for (const country of COUNTRIES) {
    const { value } = country;
    if (value === normalizedInput) return value;
  }

  throw new Error("Bitte ein Land auswählen");
}

export function validateString(name: string, input: string): string {
  const normalizedInput = input.trim();

  if (normalizedInput !== "") {
    return normalizedInput;
  }

  throw new Error(`Bitte ${name} angeben`);
}

export function validateCountryCode(input: CountryCode | "other"): CountryCode | "other" {
  const normalizedInput = input.trim();

  if (normalizedInput === "other") return "other";

  for (const countryCode of COUNTRY_CODES) {
    const { code } = countryCode;
    if (code === normalizedInput) return code;
  }

  throw new Error("Bitte eine Ländervorwahl auswählen");
}

const PHONE_NUMBER_REGEXP = /^[\d+\-/ ]*$/;

export function validatePhoneNumber(isOptional: boolean, input: string): string {
  const normalizedInput = input.trim();

  if (normalizedInput === "" && !isOptional) {
    throw new Error("Bitte eine Telefonnummer angeben");
  }

  if (PHONE_NUMBER_REGEXP.test(normalizedInput)) {
    return normalizedInput;
  }

  throw new Error("Bitte gültige Telefonnummer angeben");
}

export function validateBoolean(input: boolean): boolean {
  return input;
}

export function validateAnimalType(input: AnimalType | undefined): AnimalType {
  const normalizedInput = input?.trim();

  switch (normalizedInput) {
    case "cat":
    case "dog":
    case "small":
      return normalizedInput;
  }

  throw new Error("Bitte Tierart auswählen");
}

export function validateAnimalStage(input: AnimalStage): AnimalStage {
  const normalizedInput = input.trim();

  switch (normalizedInput) {
    case "dead":
    case "soon":
    case "forehand":
      return normalizedInput;
  }

  throw new Error("Bitte eine Option auswählen");
}

export function validateAnimalReturnType(input: AnimalReturnType): AnimalReturnType {
  const normalizedInput = input.trim();

  switch (normalizedInput) {
    case "dhl":
    case "pickup":
      return normalizedInput;
  }

  throw new Error("Bitte eine Option auswählen");
}

export function validateAnyString(input: string): string {
  return input;
}
