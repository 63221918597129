import { usePurchaseContext } from "../../contexts/purchase";
import { FormErrorMessage } from "../FormErrorMessage";
import { RadioGroup } from "../RadioGroup";

export function PurchaseReturnType() {
  const { purchaseInformation, updateField } = usePurchaseContext();

  return (
    <>
      <div className="font-bold mt-7 mb-3">Rückführung</div>
      <RadioGroup
        buttons={[
          { caption: "Rückführung per DHL", value: "dhl", extra: "10 €" },
          { caption: "Abholung am Standort", value: "pickup", extra: "Kostenfrei" },
        ]}
        formKey="returnType"
        setValue={updateField}
        formValue={purchaseInformation}
        className="py-4 px-4 mb-3 text-sm rounded-lg border-slate-400 border bg-opacity-50"
        checkedClassName="bg-green"
        uncheckedClassName="bg-white"
      />
      <FormErrorMessage formKeys={["returnType"]} />
    </>
  );
}
