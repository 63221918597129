import { usePurchaseContext } from "../../contexts/purchase";
import { CountrySelect } from "../CountrySelect";
import { FancyFormTextInput } from "../FancyTextInput";
import { FormErrorMessage } from "../FormErrorMessage";
import { PhoneInput } from "../PhoneInput";

export function PurchaseInvoiceAddress() {
  const {
    purchaseInformation,
    errors,
    updateField,
    onInvoiceAddressCountryChange,
    invoiceAddressPredictions,
    cancelInvoiceAddressPrediction,
    selectInvoiceAddressPrediction,
  } = usePurchaseContext();

  return (
    <>
      <div className="font-bold mt-7 mb-3">Rechnungsanschrift</div>
      <CountrySelect
        country={purchaseInformation?.invoiceAddressCountry}
        setCountry={(country) => {
          updateField("invoiceAddressCountry", country);
          onInvoiceAddressCountryChange(country);
        }}
        hasError={errors.invoiceAddressCountry !== undefined}
      />
      <div className="flex gap-4 pt-3">
        <FancyFormTextInput
          label="Vorname"
          type="text"
          formKey="invoiceAddressFirstName"
          formValue={purchaseInformation}
          setFormValue={updateField}
          errors={errors}
        />
        <FancyFormTextInput
          label="Nachname"
          type="text"
          formKey="invoiceAddressLastName"
          formValue={purchaseInformation}
          setFormValue={updateField}
          errors={errors}
        />
      </div>
      <div className="pt-3" />
      <FancyFormTextInput
        label="Firma (optional)"
        type="text"
        formKey="invoiceAddressCompany"
        formValue={purchaseInformation}
        setFormValue={updateField}
        errors={errors}
      />
      <div className="pt-3" />
      <FancyFormTextInput
        label="Straße und Hausnummer"
        type="text"
        formKey="invoiceAddressStreet"
        formValue={purchaseInformation}
        setFormValue={updateField}
        errors={errors}
        mapsPredictions={invoiceAddressPredictions}
        cancelPredictions={cancelInvoiceAddressPrediction}
        selectPrediction={selectInvoiceAddressPrediction}
      />
      <div className="flex gap-4 pt-3">
        <FancyFormTextInput
          label="Postleitzahl"
          type="text"
          formKey="invoiceAddressZipCode"
          formValue={purchaseInformation}
          setFormValue={updateField}
          errors={errors}
        />
        <FancyFormTextInput
          label="Stadt"
          type="text"
          formKey="invoiceAddressCity"
          formValue={purchaseInformation}
          setFormValue={updateField}
          errors={errors}
        />
      </div>
      <div className="pt-3" />
      <PhoneInput
        countryCode={purchaseInformation?.invoiceAddressPhoneCountryCode}
        number={purchaseInformation?.invoiceAddressPhoneNumber}
        setCountryCode={(countryCode) => updateField("invoiceAddressPhoneCountryCode", countryCode)}
        setNumber={(number) => updateField("invoiceAddressPhoneNumber", number)}
        id="invoice-address-phone"
        hasError={errors.invoiceAddressPhoneNumber !== undefined}
      />
      <FormErrorMessage
        formKeys={[
          "invoiceAddressCountry",
          "invoiceAddressFirstName",
          "invoiceAddressLastName",
          "invoiceAddressStreet",
          "invoiceAddressZipCode",
          "invoiceAddressCity",
          "invoiceAddressPhoneCountryCode",
          "invoiceAddressPhoneNumber",
        ]}
      />
    </>
  );
}
