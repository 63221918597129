export type Currency = "EUR";

export function renderPrice(
  price: string | number,
  currency: Currency = "EUR",
  omitDecimalsForIntegers = false
): string {
  let currencySymbol;
  switch (currency) {
    case "EUR":
      currencySymbol = "€";
      break;
  }

  const rawPrice = Number(price);
  let numberString;
  if (omitDecimalsForIntegers && isInteger(rawPrice)) {
    numberString = String(Math.round(rawPrice));
  } else {
    numberString = `${Math.floor(rawPrice)},${String(Math.round(rawPrice * 100) % 100).padStart(2, "0")}`;
  }
  return `${numberString}\u00a0${currencySymbol}`;
}

export function isInteger(number: number): boolean {
  return Math.round(number) === number;
}
