import { FunctionComponent, Fragment, PropsWithChildren } from "react";

import { HeyFlowLeadProvider } from "./heyFlowLead";
import { ProductsProvider } from "./products";
import { PopupsProvider } from "./popups";
import { PurchaseProvider } from "./purchase";
import { PaymentProvider } from "./payment";

const providers = [PopupsProvider, HeyFlowLeadProvider, ProductsProvider, PurchaseProvider, PaymentProvider];

export const ContextProviders: FunctionComponent<PropsWithChildren> = (props) => {
  const { children } = props;

  return (
    <Fragment>
      {providers.reduceRight((acc, Provider) => {
        return <Provider>{acc}</Provider>;
      }, children)}
    </Fragment>
  );
};
