import { usePurchaseContext } from "../../contexts/purchase";
import { FancyFormTextInput } from "../FancyTextInput";

export function PurchaseRemarks() {
  const { purchaseInformation, errors, updateField } = usePurchaseContext();

  return (
    <>
      <div className="font-bold mt-7 mb-3">Sonstige Anmerkungen</div>
      <FancyFormTextInput
        label="Anmerkungen / Sonstiges"
        type="textarea"
        formKey="remarks"
        formValue={purchaseInformation}
        setFormValue={updateField}
        errors={errors}
      />
    </>
  );
}
