import { useNavigate, useParams } from "react-router-dom";
import { Header } from "../components/Header";

import previous from "../components/assets/back.png";
import { useProductsContext } from "../contexts/products";
import { useEffect } from "react";
import { ProductDetails } from "../components/ProductDetails";
import { Container } from "../components/Container";
import { Spinner } from "../components/Spinner";
import { CartItem, useHeyFlowLeadContext } from "../contexts/heyFlowLead";
import { Footer } from "../components/Footer";
import { Popup } from "../components/Popup";

export function Product() {
  const { heyflowId, productId } = useParams();

  const navigate = useNavigate();

  const { setHeyflowId, addCartItem } = useHeyFlowLeadContext();
  const { asyncProduct } = useProductsContext();
  const asyncProductFetch = asyncProduct.fetch;
  const product =
    heyflowId !== undefined && productId !== undefined ? asyncProduct.getValue(heyflowId, productId) : undefined;

  useEffect(() => {
    if (heyflowId !== undefined && productId !== undefined && product === undefined) {
      asyncProductFetch(heyflowId, productId);
    }
  }, [heyflowId, productId, asyncProductFetch, product]);

  useEffect(() => {
    setHeyflowId(heyflowId);
  }, [setHeyflowId, heyflowId]);

  return (
    <div className="min-h-screen flex flex-col items-stretch">
      <Header />
      <div className="flex-1">
        <Container className="flex-1">
          <button type="button" onClick={() => navigate(-1)} className="flex items-center">
            <img src={previous} alt="previous" className="w-7"></img>
            <span className="text-base pl-2">Zurück zur Auswahl</span>
          </button>
          {product ? (
            <ProductDetails
              addItem={(item: CartItem, goBackToPurchase: boolean) => {
                addCartItem(item);
                navigate(goBackToPurchase ? `/${heyflowId}/purchase` : `/${heyflowId}`);
              }}
              product={product.product}
              productVariation={product.variation}
            ></ProductDetails>
          ) : (
            <div className="flex flex-col items-center py-10">
              <Spinner size="large"></Spinner>
              <div className="text-neutral-600 mt-10">Produktinformationen laden</div>
            </div>
          )}
        </Container>
      </div>
      <Footer></Footer>
      <Popup />
    </div>
  );
}
