import { Context, createContext, FunctionComponent, PropsWithChildren } from "react";
import { useContext as useReactContext } from "react";

// this is a helper to create a hook of type () => T from a context of type Context<T | undefined>
function createContextHook<T>(context: Context<T | undefined>): () => T {
  return () => {
    const contextValue = useReactContext(context);
    if (contextValue === undefined) {
      throw new Error("Context must be inside a Provider");
    }
    return contextValue;
  };
}

// this is a helper to create a context provider for a given context of type Context<T | undefined>
// implementing the behavior provided by a hook of type () => T
// this hook can be created through the function createContextHook
function createProvider<T>(context: Context<T | undefined>, useContextImplementation: () => T) {
  const { Provider } = context;
  const ImplementedProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
    const transferAccessors = useContextImplementation();

    return <Provider value={transferAccessors}>{children}</Provider>;
  };

  return ImplementedProvider;
}

// this helper creates a context, a provider for that context and a hook to use the context
export function createContextAndProvider<T>(useContextImplementation: () => T) {
  const context = createContext<T | undefined>(undefined);
  const useContext = createContextHook(context);
  const Provider = createProvider(context, useContextImplementation);
  return { useContext, Provider };
}
