import { COUNTRIES, Country } from "../components/CountrySelect";

export function createAddressString(
  name: string,
  street: string,
  zipCode: string,
  city: string,
  country: Country
): string {
  const invoiceCountry = COUNTRIES.find(({ value }) => value === country)!.text;
  const addressComponents = [name, street, `${zipCode} ${city}`, invoiceCountry];
  return addressComponents
    .map((component) => component.trim())
    .filter((component) => component.length > 0)
    .join(", ");
}
