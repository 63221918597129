import { useEffect, useRef } from "react";

export function useRegularUpdate<T>(value: T, process: (value: T) => Promise<void>, delayMilliseconds: number) {
  const timer = useRef<number | undefined>(undefined);
  const currentValue = useRef<T>(value);

  currentValue.current = value;

  useEffect(() => {
    if (timer.current !== undefined) {
      return;
    }

    timer.current = setTimeout(async () => {
      timer.current = undefined;
      await process(currentValue.current);
    }, delayMilliseconds);
  }, [value, delayMilliseconds, process]);
}
