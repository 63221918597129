import { useCallback, useState } from "react";

export interface FetchValue<S extends readonly unknown[], T> {
  value: T | undefined;
  isLoading: boolean;
  error: string | undefined;
  fetch: (...fetchArgs: S) => Promise<T | undefined>;
  setValue: React.Dispatch<React.SetStateAction<T | undefined>>;
}

export interface FetchValues<S extends readonly unknown[], T> {
  getValue: (...args: S) => T | undefined;
  getIsLoading: (...args: S) => boolean;
  getError: (...args: S) => string | undefined;
  fetch: (...fetchArgs: S) => Promise<T | undefined>;
  setValue: (value: T, ...args: S) => void;
}

export interface ReadOnlyFetchValue<T> {
  value: T | undefined;
  isLoading: boolean;
  error: string | undefined;
}

// stability
// - fetch: stable if fetcher and retryTimes are stable
// - setValue: stable
// - value only changes upon calls to setValue or
//   when the fetcher is called and successfully returns
export function useAsyncValue<S extends readonly unknown[], T>(
  fetcher: (...fetchArgs: S) => Promise<T>,
  retryTimes?: number | undefined
): FetchValue<S, T> {
  const [value, setValue] = useState<T | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const fetch = useCallback(
    async (...fetchArgs: S) => {
      let retries = retryTimes;

      try {
        setIsLoading(true);
        setError(undefined);

        for (let retryDelay = 1000; ; retryDelay *= 2) {
          try {
            const result = await fetcher(...fetchArgs);
            setValue(result);
            return result;
          } catch (error) {
            if (retries === undefined || retries === 0) {
              throw error;
            }
            await new Promise((resolve) => setTimeout(resolve, retryDelay));
            retries--;
          }
        }
      } catch (error) {
        setError((error as Error).message);
      } finally {
        setIsLoading(false);
      }
    },
    [fetcher, retryTimes]
  );

  const externalResetValue: typeof setValue = useCallback((value) => {
    setValue(value);
    setError(undefined);
  }, []);

  return { value, isLoading, error, fetch, setValue: externalResetValue };
}

// stability
// - getValue: unstable
// - getIsLoading: stabe
// - getError: stable
// - fetch: stable if fetcher and retryTimes are stable
// - setValue: stable
export function useAsyncValues<S extends readonly unknown[], T>(
  fetcher: (...fetchArgs: S) => Promise<T>,
  retryTimes?: number | undefined
): FetchValues<S, T> {
  const [value, setValue] = useState<Record<string, T | undefined>>({});
  const [isLoading, setIsLoading] = useState<Record<string, boolean>>({});
  const [error, setError] = useState<Record<string, string | undefined>>({});

  const fetch = useCallback(
    async (...fetchArgs: S) => {
      const key = JSON.stringify(fetchArgs);
      let retries = retryTimes;

      try {
        setIsLoading((isLoading) => ({ ...isLoading, [key]: true }));
        setError((error) => ({ ...error, [key]: undefined }));

        for (let retryDelay = 1000; ; retryDelay *= 2) {
          try {
            const result = await fetcher(...fetchArgs);
            setValue((value) => ({ ...value, [key]: result }));
            return result;
          } catch (error) {
            if (retries === undefined || retries === 0) {
              throw error;
            }
            await new Promise((resolve) => setTimeout(resolve, retryDelay));
            retries--;
          }
        }
      } catch (errorValue) {
        setError((error) => ({ ...error, [key]: (errorValue as Error).message }));
      } finally {
        setIsLoading((isLoading) => ({ ...isLoading, [key]: false }));
      }
    },
    [fetcher, retryTimes]
  );

  const externalResetValue = useCallback((value: T, ...args: S) => {
    const key = JSON.stringify(args);
    setValue((values) => ({ ...values, [key]: value }));
    setError((error) => ({ ...error, [key]: undefined }));
  }, []);

  const getValue = useCallback(
    (...args: S) => {
      const key = JSON.stringify(args);
      return value[key];
    },
    [value]
  );

  const getIsLoading = useCallback(
    (...args: S) => {
      const key = JSON.stringify(args);
      return isLoading[key];
    },
    [isLoading]
  );

  const getError = useCallback(
    (...args: S) => {
      const key = JSON.stringify(args);
      return error[key];
    },
    [error]
  );

  return { getValue, getIsLoading, getError, fetch, setValue: externalResetValue };
}
