interface Props {
  small?: boolean;
}

export function DropdownIcon({ small = false }: Props) {
  return (
    <div
      className={`${small ? "px-1" : "border-l border-l-slate-300 border-solid px-2 ml-2"}
     flex items-center h-4  pointer-events-none`}
    >
      <div
        className={`${
          small ? "border-t-[7px] border-x-[5px]" : "border-[7px]"
        }  border-solid border-t-slate-500 border-x-transparent border-b-transparent border-b-0`}
      ></div>
    </div>
  );
}
