import { useNavigate } from "react-router-dom";
import { PurchaseDetails } from "../components/PurchaseDetails";
import { PurchasePageLayout } from "../components/PurchasePageLayout";

export function Purchase() {
  const navigate = useNavigate();

  return (
    <PurchasePageLayout
      isLoadingExtraData={false}
      loadingMessage="Dein Angebot wird geladen"
      createHeadline={(lead) => `Mein Angebot #${lead.offerId} jetzt abschließen`}
      renderChild={({ lead, heyflowId }) => (
        <PurchaseDetails
          lead={lead}
          heyflowId={heyflowId}
          proceedToCheck={() => {
            navigate(`/${heyflowId}/check`);
          }}
        ></PurchaseDetails>
      )}
    />
  );
}
