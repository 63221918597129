import { CSSProperties, HTMLInputTypeAttribute, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { camelToSnake } from "../utils/casing";
import { PlacePrediction } from "../utils/api";

interface FancyTextInputProps {
  label: string;
  id: string;
  type: HTMLInputTypeAttribute;
  value: string;
  mapsPredictions?: PlacePrediction[];
  cancelPredictions?: () => void;
  selectPrediction?: (newValue: string, placeId: string) => void;
  setValue: (value: string) => void;
  hasError: boolean;
  textAreaInitialRows?: number;
}

interface FancyFormInputProps<T extends string, S extends Record<T, string> | undefined> {
  label: string;
  formKey: T;
  formValue: S;
  errors: Partial<Record<T, string>>;
  mapsPredictions?: PlacePrediction[];
  cancelPredictions?: () => void;
  selectPrediction?: (newValue: string, placeId: string) => void;
  removePredictions?: () => void;
  setFormValue: (key: T, newValue: string) => void;
  type: HTMLInputTypeAttribute | "textarea";
  textAreaInitialRows?: number;
}

export const MEASURES = {
  width: "100%",
  fontSizeMeasure: 0.875,
  fontSizeUnit: "rem",
  labelHeightMultiplier: 1.5,
  unit: "rem",
  inputPaddingX: 0.875,
  inputPaddingY: 0.75,
  focusLabelScale: 0.75,
  borderRadius: 0.375,
  borderWidth: 0.0625,
  borderFocusWidth: 0.125,
  legendHeight: 0.688,
  legendPadding: 0.313,
};

interface Styles {
  wrapperDiv: CSSProperties;
  label: CSSProperties;
  labelFocus: CSSProperties;
  innerDiv: CSSProperties;
  input: CSSProperties;
  fieldSet: CSSProperties;
  fieldSetFocus: CSSProperties;
  legend: CSSProperties;
  legendSpan: CSSProperties;
}

function createStyles({
  borderRadius,
  focusLabelScale,
  fontSizeMeasure,
  fontSizeUnit,
  unit,
  inputPaddingX,
  inputPaddingY,
  labelHeightMultiplier,
  width,
  borderWidth,
  borderFocusWidth,
  legendHeight,
  legendPadding,
}: typeof MEASURES): Styles {
  const fontSize = `${fontSizeMeasure}${fontSizeUnit}`;
  const lineHeight = `${fontSizeMeasure * labelHeightMultiplier}${fontSizeUnit}`;

  const labelStyle: CSSProperties = {
    fontSize,
    lineHeight,
    maxWidth: `calc(100% - ${inputPaddingX * 2}${unit})`,
    transform: `translate(${inputPaddingX}${unit}, ${inputPaddingY}${unit})`,
  };

  const fieldSetStyle: CSSProperties = {
    borderWidth: `${borderWidth}${unit}`,
    top: `-${(legendHeight - borderWidth) / 2}${unit}`,
    padding: `${0} ${inputPaddingX - borderWidth - legendPadding}${unit}`,
    borderRadius: `${borderRadius}${unit}`,
  };

  return {
    wrapperDiv: { width: width },
    label: labelStyle,
    labelFocus: {
      ...labelStyle,
      maxWidth: `calc(${100 / focusLabelScale}% - ${(inputPaddingX * 2) / focusLabelScale}${unit})`,
      transform: `translate(${inputPaddingX}${unit}, -${
        (fontSizeMeasure * labelHeightMultiplier * focusLabelScale) / 2
      }${fontSizeUnit}) scale(${focusLabelScale})`,
      userSelect: "none",
    },
    innerDiv: {
      fontSize,
      lineHeight,
      borderRadius: `${borderRadius}${unit}`,
    },
    input: {
      fontSize,
      height: lineHeight,
      padding: `${inputPaddingY}${unit} ${inputPaddingX}${unit}`,
    },
    fieldSet: fieldSetStyle,
    fieldSetFocus: { ...fieldSetStyle, borderWidth: `${borderFocusWidth}${unit}` },
    legend: { height: `${legendHeight}${unit}`, fontSize: `${fontSizeMeasure * focusLabelScale}${fontSizeUnit}` },
    legendSpan: { paddingLeft: `${legendPadding}${unit}`, paddingRight: `${legendPadding}${unit}` },
  };
}

const STYLES = createStyles(MEASURES);

export const FancyTextInput = memo(
  ({
    label,
    id,
    type,
    value,
    hasError,
    setValue,
    mapsPredictions,
    cancelPredictions,
    selectPrediction,
    textAreaInitialRows,
  }: FancyTextInputProps) => {
    const [hasFocus, setHasFocus] = useState(false);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const [markedPredictionIndex, setMarkedPredictionIndex] = useState<number | undefined>(undefined);

    const actualValueToUse =
      markedPredictionIndex !== undefined &&
      mapsPredictions !== undefined &&
      mapsPredictions.length > markedPredictionIndex
        ? mapsPredictions[markedPredictionIndex].description
        : value;

    useEffect(() => {
      setMarkedPredictionIndex(undefined);
    }, [mapsPredictions, value]);

    const elementRef = useRef<HTMLDivElement>(null);

    const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (mapsPredictions === undefined) {
        return;
      }

      if (event.code === "ArrowDown") {
        event.preventDefault();
        setMarkedPredictionIndex((prev) =>
          mapsPredictions.length > 0
            ? prev === undefined
              ? 0
              : Math.min(prev + 1, mapsPredictions.length - 1)
            : undefined
        );
      } else if (event.code === "ArrowUp") {
        event.preventDefault();
        setMarkedPredictionIndex((prev) =>
          mapsPredictions.length > 0
            ? prev === undefined
              ? mapsPredictions.length - 1
              : Math.max(prev - 1, 0)
            : undefined
        );
      } else if (event.code === "Escape") {
        event.preventDefault();
        setMarkedPredictionIndex(undefined);
        if (cancelPredictions !== undefined) cancelPredictions();
      } else if (event.code === "Enter") {
        event.preventDefault();
        if (
          selectPrediction !== undefined &&
          markedPredictionIndex !== undefined &&
          mapsPredictions[markedPredictionIndex] !== undefined
        ) {
          selectPrediction(actualValueToUse, mapsPredictions[markedPredictionIndex].placeId);
        }
      }
    };

    const rescaleTextArea = useCallback(() => {
      const textArea = textAreaRef.current;
      if (textArea === null) return;

      textArea.style.height = "inherit";
      const computed = window.getComputedStyle(textArea);

      // Calculate the height
      const height =
        -parseInt(computed.getPropertyValue("border-top-width"), 10) -
        parseInt(computed.getPropertyValue("padding-top"), 10) +
        textArea.scrollHeight -
        parseInt(computed.getPropertyValue("padding-bottom"), 10) -
        parseInt(computed.getPropertyValue("border-bottom-width"), 10);

      textArea.style.height = height + "px";
    }, []);

    useEffect(rescaleTextArea, [rescaleTextArea]);

    const moveLabelUp =
      hasFocus || actualValueToUse.length > 0 || type === "datetime-local" || type === "date" || type === "time";

    return (
      <>
        <div
          className="inline-flex flex-col relative min-w-0 p-0 border-0 align-top"
          style={STYLES.wrapperDiv}
          ref={elementRef}
          onKeyDown={onKeyDown}
          onBlur={(event) => {
            if (!elementRef.current?.contains(event.relatedTarget as Node)) {
              cancelPredictions?.();
            }
          }}
        >
          <label
            className={`text-slate-600 font-normal p-0 block origin-top-left whitespace-nowrap overflow-hidden text-ellipsis absolute top-0 left-0 z-10 pointer-events-none transition-all ${
              hasFocus ? " text-sky-600" : ""
            }`}
            htmlFor={id}
            style={moveLabelUp ? STYLES.labelFocus : STYLES.label}
          >
            {label}
          </label>
          <div
            className="group text-slate-900 box-border cursor-text inline-flex items-center relative"
            style={STYLES.innerDiv}
          >
            {type !== "textarea" ? (
              <input
                id={id}
                data-1p-ignore
                type={type}
                className="outline-none border-none box-content bg-transparent m-0 block min-w-0 w-full"
                onFocus={() => {
                  setHasFocus(true);
                }}
                onBlur={() => {
                  setHasFocus(false);
                }}
                value={actualValueToUse}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
                style={STYLES.input}
              />
            ) : (
              <textarea
                id={id}
                data-1p-ignore
                rows={textAreaInitialRows ?? 3}
                ref={textAreaRef}
                className="outline-none border-none box-content bg-transparent m-0 block min-w-0 w-full resize-none"
                onFocus={() => {
                  setHasFocus(true);
                }}
                onBlur={() => {
                  setHasFocus(false);
                }}
                value={actualValueToUse}
                onChange={(e) => {
                  rescaleTextArea();
                  setValue(e.target.value);
                }}
                style={STYLES.input}
              />
            )}
            <fieldset
              aria-hidden="true"
              className={`text-left absolute bottom-0 right-0 left-0 m-0 pointer-events-none rounded-md border-solid overflow-hidden min-w-[0%]  ${
                hasError
                  ? " border-red-600 group-hover:border-red-600"
                  : hasFocus
                    ? " border-sky-600 group-hover:border-sky-600"
                    : "border-slate-400 group-hover:border-slate-950"
              }`}
              style={hasFocus || hasError ? STYLES.fieldSetFocus : STYLES.fieldSet}
            >
              <legend
                className={`w-auto overflow-hidden block p-0 invisible max-w-[0.01px] whitespace-nowrap transition-all ${
                  moveLabelUp ? "max-w-full" : ""
                }`}
                style={STYLES.legend}
              >
                <span className="inline-block opacity-0 visible" style={STYLES.legendSpan}>
                  {label}
                </span>
              </legend>
            </fieldset>
          </div>
          {mapsPredictions && mapsPredictions.length > 0 && (
            <div
              className="absolute top-full w-full bg-white border-slate-400 z-20 border-solid border shadow-lg"
              onKeyDown={onKeyDown}
              tabIndex={1} // this will ensure that this element is focus and the blur event will refer to it
            >
              {mapsPredictions.map(({ description, placeId, matchedSubstrings }, mapsPredictionIndex) => {
                const fragments: { text: string; bold: boolean }[] = [];
                let currentIndex = 0;
                matchedSubstrings.forEach(({ offset, length }) => {
                  if (offset > currentIndex) {
                    fragments.push({ text: description.slice(currentIndex, offset), bold: false });
                  }
                  fragments.push({ text: description.slice(offset, offset + length), bold: true });
                  currentIndex = offset + length;
                });

                if (currentIndex < description.length) {
                  fragments.push({ text: description.slice(currentIndex), bold: false });
                }

                return (
                  <div
                    key={placeId}
                    className={` px-3 py-2 hover:bg-neutral-200 cursor-pointer border-b-slate-300 border-solid border-b last:border-none ${
                      mapsPredictionIndex === markedPredictionIndex ? "bg-sky-200" : ""
                    }`}
                    style={{ fontSize: `${MEASURES.fontSizeMeasure}${MEASURES.fontSizeUnit}` }}
                    onKeyDown={onKeyDown}
                    onClick={() => {
                      if (selectPrediction !== undefined) {
                        selectPrediction(actualValueToUse, placeId);
                      }
                    }}
                  >
                    {fragments.map(({ text, bold }, index) => (
                      <span key={index} className={bold ? "font-bold" : ""}>
                        {text}
                      </span>
                    ))}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </>
    );
  }
);

export function FancyFormTextInput<T extends string, S extends Record<T, string> | undefined>({
  formValue,
  formKey,
  label,
  setFormValue,
  cancelPredictions,
  selectPrediction,
  mapsPredictions,
  type,
  errors,
  textAreaInitialRows,
}: FancyFormInputProps<T, S>) {
  const id = useMemo(() => camelToSnake(formKey), [formKey]);

  const setValue = useCallback(
    (newValue: string) => {
      setFormValue(formKey, newValue);
    },
    [setFormValue, formKey]
  );

  return (
    <FancyTextInput
      id={id}
      label={label}
      setValue={setValue}
      mapsPredictions={mapsPredictions}
      cancelPredictions={cancelPredictions}
      selectPrediction={selectPrediction}
      value={formValue?.[formKey] ?? ""}
      hasError={errors[formKey] !== undefined}
      type={type}
      textAreaInitialRows={textAreaInitialRows}
    />
  );
}
