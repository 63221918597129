import { useEffect } from "react";
import { Header } from "./Header";
import { Container } from "./Container";
import { useParams } from "react-router-dom";
import { Lead, useHeyFlowLeadContext } from "../contexts/heyFlowLead";
import { PurchaseSummary } from "./PurchaseSummary";
import { PurchaseAssistant } from "./PurchaseAssistant";
import { NextSteps } from "./NextSteps";
import { Footer } from "./Footer";
import { Popup } from "./Popup";
import { Spinner } from "./Spinner";

import { Headline } from "./Headline";

interface ChildProps {
  lead: Lead;
  heyflowId: string;
}

interface Props {
  isLoadingExtraData: boolean;
  loadingMessage: string;
  showHeaderTickMark?: boolean;
  dontShowPurchaseEditLink?: boolean;
  dontShowNextStepsAndTestimonials?: boolean;
  createHeadline: (lead: Lead) => string;
  renderChild: (props: ChildProps) => JSX.Element;
}

export function PurchasePageLayout({
  isLoadingExtraData,
  loadingMessage,
  showHeaderTickMark,
  dontShowPurchaseEditLink,
  dontShowNextStepsAndTestimonials,
  createHeadline,
  renderChild,
}: Props) {
  const { heyflowId } = useParams();

  const { setHeyflowId, isLoading, value } = useHeyFlowLeadContext();

  useEffect(() => {
    setHeyflowId(heyflowId);
  }, [setHeyflowId, heyflowId]);

  return (
    <div className="min-h-screen flex flex-col text-neutral-900">
      <Header />

      <div className="flex-1">
        <Container className="flex-1 lg:-mt-4">
          {isLoading || heyflowId == undefined || value === undefined || isLoadingExtraData ? (
            <>
              <div className="flex justify-center my-14">
                <Spinner size="large"></Spinner>
              </div>
              <span className="text-center block text-base md:text-lg">{loadingMessage}</span>
            </>
          ) : (
            <>
              <Headline
                title={createHeadline(value.lead)}
                type="sub"
                showTickMark={showHeaderTickMark}
                className="lg:hidden"
              />

              <div className="flex lg:flex-row max-lg:flex-col justify-between">
                <div className="shrink-0 lg:w-6/12 max-lg:order-2 max-lg:mt-4 lg:pt-4">
                  <Headline
                    title={createHeadline(value.lead)}
                    type="sub"
                    showTickMark={showHeaderTickMark}
                    className="max-lg:hidden"
                  />
                  {renderChild({ lead: value.lead, heyflowId })}
                </div>
                <div className="shrink-0 lg:w-5/12 lg:self-start top-0 lg:sticky pt-4 max-lg:order-1">
                  <div className="max-lg:hidden">
                    <PurchaseSummary
                      cart={value.cart}
                      heyflowId={heyflowId}
                      lead={value.lead}
                      expandable={false}
                      dontShowEditLink={dontShowPurchaseEditLink}
                    />
                    <div className="h-8" />
                    <PurchaseAssistant lead={value.lead} />
                  </div>
                  <div className="lg:hidden">
                    <PurchaseSummary
                      cart={value.cart}
                      heyflowId={heyflowId}
                      lead={value.lead}
                      expandable={true}
                      dontShowEditLink={dontShowPurchaseEditLink}
                    />
                  </div>
                </div>
              </div>
              {dontShowNextStepsAndTestimonials !== true && (
                <>
                  <div className="h-9" />
                  <div className="lg:hidden pb-4">
                    <PurchaseAssistant lead={value.lead} />
                  </div>
                  <NextSteps showDescription={false} />
                  <Headline title="Das sagen unsere Kunden" type="sub" className="mt-10 lg:mt-20 mb-4" />
                  <div className="elfsight-app-068e60fc-2b38-47ea-a275-765c9384308a" data-elfsight-app-lazy></div>
                </>
              )}
            </>
          )}
        </Container>
      </div>
      <Footer></Footer>
      <Popup />
    </div>
  );
}
