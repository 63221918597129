import { CartItem, CartItemType, StandardProducts, transformProductToCartItem } from "../contexts/heyFlowLead";

const PREDEFINED_PRODUCT_IDS = {
  energy: 27456,
  pawPrint: 24938,
  transport: 41103,
  certificate: 40874,
  diamondConsultation: 40875,
};

export const LEINEN_SAECKCHEN_PRODUCT_ID = 41033;

export function createStandardProductDefinitions(standardProducts: StandardProducts) {
  const pawPrint = transformProductToCartItem(
    standardProducts[String(PREDEFINED_PRODUCT_IDS.pawPrint)].product,
    "pawPrint"
  );

  const certificate = transformProductToCartItem(
    standardProducts[String(PREDEFINED_PRODUCT_IDS.certificate)].product,
    "certificate"
  );

  const placeholderProducts: Record<CartItemType, CartItem> = {
    cremation: {
      name: "Einzelkremierung, Tiergewicht bis 1 kg",
      price: 100,
      productId: 27448,
      description:
        "Der Preis der Sammelkremierung ist abhängig vom Gewicht deines Tieres. Sollte dein Tier leichter sein als angegeben, überweisen wir dir den Differenzbetrag zurück.",
      image: "https://animaltree.de/wp-content/uploads/2022/01/Blaetter-Logo_high_res-1024x1024.png",
      type: "cremation",
      inscriptionIncluded: false,
    },
    energy: transformProductToCartItem(standardProducts[String(PREDEFINED_PRODUCT_IDS.energy)].product, "energy"),
    pawPrint,
    transport: {
      name: "Abgabe oder Abholung",
      price: -1,
      productId: 41103,
      description: "Du entscheidest, ob du dein Tier selber abgeben oder abholen lassen möchtest.",
      image: "https://animaltree.de/wp-content/uploads/2023/10/partnerkrematorien-animal-tree_website-2.jpg",
      type: "transport",
      inscriptionIncluded: false,
    },
    urn: {
      name: "Keine Urne ausgewählt",
      price: -1,
      productId: LEINEN_SAECKCHEN_PRODUCT_ID,
      description: "",
      image: "https://animaltree.de/wp-content/uploads/2023/10/JU4A1516-jpg.jpg",
      type: "urn",
      inscriptionIncluded: false,
    },
    inscription: {
      name: "Beschriftungen",
      price: -1,
      productId: 36098,
      description: "",
      image: "https://animaltree.de/wp-content/uploads/2023/03/Beschriftung-mit-Pusteblume.jpg",
      type: "inscription",
      inscriptionIncluded: false,
    },
    jewelry: {
      name: "Ascheschmuck und Andenken",
      price: -1,
      productId: 35721,
      description: "",
      image: "https://animaltree.de/wp-content/uploads/2023/03/Gedenkstein-Herz-mit-Rose.jpg",
      type: "jewelry",
      inscriptionIncluded: false,
    },
    certificate,
    diamondConsultation: transformProductToCartItem(
      standardProducts[String(PREDEFINED_PRODUCT_IDS.diamondConsultation)].product,
      "diamondConsultation"
    ),
  };

  const transportProducts: Record<"pickUp" | "dropOff", CartItem> = {
    pickUp: {
      name: "Abholung bei dir oder einem Tierarzt",
      price:
        Math.round(
          Number(standardProducts["41103"]?.variations?.find((variation) => variation.id === 41104)?.price) * 100
        ) / 100, // 69,
      variationId: 41104,
      productId: 41103,
      description: "Abholung bei dir oder deinem Tierarzt.",
      image: "https://animaltree.de/wp-content/uploads/2023/10/partnerkrematorien-animal-tree_website-2.jpg",
      type: "transport",
      inscriptionIncluded: false,
    },
    dropOff: {
      name: "Abgabe beim Standort",
      price:
        Math.round(
          Number(standardProducts["41103"]?.variations?.find((variation) => variation.id === 41105)?.price) * 100
        ) / 100, //0,
      variationId: 41105,
      productId: 41103,
      description: "Ich fahre selber zum Standort.",
      image: "https://animaltree.de/wp-content/uploads/2023/10/partnerkrematorien-animal-tree_website-2.jpg",
      type: "transport",
      inscriptionIncluded: false,
    },
  };

  return { placeholderProducts, transportProducts };
}

export const productShops: Record<CartItemType, undefined | ((heyflowId: string) => string)> = {
  cremation: undefined,
  energy: function (heyflowId: string): string {
    return `/${heyflowId}/product/${PREDEFINED_PRODUCT_IDS.energy}`;
  },
  pawPrint: function (heyflowId: string): string {
    return `/${heyflowId}/product/${PREDEFINED_PRODUCT_IDS.pawPrint}`;
  },
  transport: function (heyflowId: string): string {
    return `/${heyflowId}/product/${PREDEFINED_PRODUCT_IDS.transport}`;
  },
  urn: function (heyflowId: string): string {
    return `/${heyflowId}/products/urnen`;
  },
  inscription: function (heyflowId: string): string {
    return `/${heyflowId}/products/beschriftung`;
  },
  jewelry: function (heyflowId: string): string {
    return `/${heyflowId}/products/schmuck`;
  },
  certificate: function (heyflowId: string): string {
    return `/${heyflowId}/product/${PREDEFINED_PRODUCT_IDS.certificate}`;
  },
  diamondConsultation: function (heyflowId: string): string {
    return `/${heyflowId}/product/${PREDEFINED_PRODUCT_IDS.diamondConsultation}`;
  },
};

export const anotherProduct: Record<CartItemType, string> = {
  cremation: "",
  energy: "",
  pawPrint: "Einen weiteren Pfotenabdruck hinzufügen",
  transport: "",
  urn: "",
  inscription: "Eine weitere Beschriftung hinzufügen",
  jewelry: "Einen weiteren Gedenkartikel hinzufügen",
  certificate: "",
  diamondConsultation: "",
};

export const removableProduct: Record<CartItemType, boolean> = {
  cremation: false,
  energy: true,
  pawPrint: true,
  transport: false,
  urn: false,
  inscription: true,
  jewelry: true,
  certificate: true,
  diamondConsultation: true,
};

export const simpleProducts: CartItemType[] = ["pawPrint", "diamondConsultation", "certificate"];
