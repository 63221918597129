import { useEffect, useMemo, useState } from "react";

function debounce<T, S>(func: (value: T) => S, timeout: number) {
  let timer: number | undefined;

  return (value: T) => {
    if (timer !== undefined) {
      clearTimeout(timer);
    }

    timer = setTimeout(() => {
      func(value);
    }, timeout);
  };
}

export const useDebouncedValue = <T>(value: T, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const debounceSet = useMemo(() => debounce(setDebouncedValue, delay), [delay]);

  useEffect(() => {
    debounceSet(value);
  }, [value, debounceSet]);

  return debouncedValue;
};
