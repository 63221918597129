import { FormKeys, usePurchaseContext } from "../contexts/purchase";

interface FormKeysProps {
  formKeys: FormKeys[];
}

interface ErrorMessageProps {
  message: string | undefined;
}

type Props = FormKeysProps | ErrorMessageProps;

export function FormErrorMessage(props: Props) {
  const { errors } = usePurchaseContext();

  let errorMessage: string | undefined = undefined;

  if ("formKeys" in props) {
    const { formKeys } = props;
    for (const key of formKeys) {
      if (errors[key] !== undefined) {
        errorMessage = errors[key];
        break;
      }
    }
  } else {
    errorMessage = props.message;
  }

  if (errorMessage === undefined) {
    return null;
  }

  return <div className="text-red-600 text-md mt-2 font-medium">{errorMessage}</div>;
}
