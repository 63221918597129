import { PropsWithChildren, memo, useCallback, useEffect, useRef } from "react";

interface Props {
  isOpen: boolean;
}

export const Expandable = memo(({ isOpen, children }: PropsWithChildren<Props>) => {
  const contentRef = useRef<HTMLDivElement>(null);

  const handleTransitionEnd = useCallback(() => {
    if (isOpen) {
      // switch to auto because we want that the height can adapt
      // dynamically while open
      contentRef.current!.style.height = "auto";
      contentRef.current!.style.overflow = "visible";
    }
  }, [isOpen]);

  useEffect(() => {
    const onTransitionEnd = handleTransitionEnd;
    const element = contentRef.current;
    element?.addEventListener("transitionend", onTransitionEnd);

    return () => {
      element?.removeEventListener("transitionend", onTransitionEnd);
    };
  }, [handleTransitionEnd]);

  useEffect(() => {
    if (contentRef.current === null) return;

    contentRef.current.style.height = window.getComputedStyle(contentRef.current).getPropertyValue("height");
    contentRef.current!.style.overflow = "hidden";

    setTimeout(() => {
      if (contentRef.current === null) return;
      contentRef.current.style.height = isOpen ? `${contentRef.current.scrollHeight}px` : "0px";
    }, 0);
  }, [isOpen]);

  let heightCss;
  if (contentRef.current === null) {
    // initial render, there must not be any transition
    heightCss = isOpen ? "auto" : "0px";
  } else {
    // transition is going to start, ensure to switch from "auto" to the actual current height
    // transitions from "auto" to a numeric height are not possible
    heightCss = contentRef.current.style.height; // window.getComputedStyle(contentRef.current).getPropertyValue("height");
  }

  return (
    <div className="transition-all duration-500 overflow-hidden" ref={contentRef} style={{ height: heightCss }}>
      {children}
    </div>
  );
});
