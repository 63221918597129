import { useCallback, useEffect } from "react";

import close from "./assets/close.png";
import { PopupTypes, usePopupsContext } from "../contexts/popups";
import { WordPressImage } from "./WordPressImage";
import { Link } from "react-router-dom";
import { Button } from "./Button";
import configuration from "../configuration";
import { productShops } from "../utils/productTypes";
import { useHeyFlowLeadContext } from "../contexts/heyFlowLead";

export interface PopupContentProps {
  popupType: PopupTypes;
}

export function PopupContent({ popupType }: PopupContentProps) {
  const { completePurchaseProcess } = useHeyFlowLeadContext();

  switch (popupType.type) {
    case "payment":
      return (
        <div className="max-w-sm text-base font-normal">
          Wir wissen, dass ein Trauerfall nicht nur emotional, sondern auch finanziell belastend sein kann. Um dir in
          dieser schwierigen Zeit entgegenzukommen, bieten wir die Möglichkeit einer Ratenzahlung an. Du kannst diese
          Option ganz einfach am Ende des Checkouts auswählen.
        </div>
      );

    case "imageDetails":
      return (
        <div className="max-w-xl">
          <WordPressImage src={popupType.image.src} alt={popupType.image.alt} showThumbNail={false}></WordPressImage>
        </div>
      );

    case "message":
      return <div className="p-4">{popupType.message}</div>;

    case "leaveEarly":
      return (
        <>
          <div className="max-w-sm text-base">Möchtest du ohne Urne fortfahren?</div>
          <div className="flex justify-between pt-5">
            <Link to={productShops.urn?.(popupType.heyflowId) ?? ""}>
              <Button caption="Urne auswählen" fullWidth={true} primary={true} large={false}></Button>
            </Link>
            <span className="w-6"></span>
            <Link to={`/${popupType.heyflowId}/purchase`} onClick={completePurchaseProcess}>
              <Button caption="Ja" fullWidth={true} primary={false} large={false}></Button>
            </Link>
          </div>
        </>
      );

    case "negativeLocation":
      return (
        <div className="p-4">
          <span>
            Leider haben wir in diesem Ort momentan
            <br />
            keine verfügbaren Kapazitäten.
          </span>
          <div className="flex justify-between pt-5">
            <a href={configuration.animaltree.urnShop}>
              <Button caption="Nur eine Urne kaufen" fullWidth={true} primary={true} large={false}></Button>
            </a>
            <span className="w-6"></span>
            <a href={configuration.animaltree.url}>
              <Button caption="Zurück" fullWidth={true} primary={false} large={false}></Button>
            </a>
          </div>
        </div>
      );
  }
}

export function Popup() {
  const { popupType, setPopupType } = usePopupsContext();

  const onClick = useCallback(() => {
    setPopupType(undefined);
  }, [setPopupType]);

  useEffect(() => {
    setPopupType(undefined);
  }, [setPopupType]);

  if (popupType === undefined) {
    return null;
  }

  return (
    <div className="fixed top-0 left-0 w-screen h-screen flex justify-center items-center z-[1999999999] animate-blendIn">
      <div onClick={onClick} className="absolute top-0 w-full h-full left-0 bg-black opacity-40"></div>
      <div className="bg-white py-5 px-8 rounded-xl z-20 max-h-screen overflow-auto animate-slideIn relative">
        <PopupContent popupType={popupType} />
        <img
          className="shrink-0 cursor-pointer absolute top-2 right-2"
          src={close}
          alt="close"
          width="15"
          onClick={onClick}
        />
      </div>
    </div>
  );
}
