import { captureMessage } from "@sentry/react";
import configuration from "../configuration";

export async function queryFetch<T extends Record<string, string> | undefined, S>(
  path: string,
  method: string,
  args: T
): Promise<S> {
  const query = new URLSearchParams(args).toString();

  try {
    const apiPath = `${configuration.api.url}${path}`;
    const response = await fetch(query ? `${apiPath}?${query}` : apiPath, {
      method,
    });

    if (response.status === 401) {
      throw new Error(`Unauthorized`);
    }

    if (response.status >= 400) {
      try {
        captureMessage(`Error response: ${await response.json()}`);
      } catch {
        /* */
      }
      throw new Error(`Response status is ${response.status}`);
    }

    return response.json();
  } catch (error: unknown) {
    let message = "Unknown error";
    if (error instanceof Error) message = error.message;
    captureMessage(`queryFetch ${method} ${path} failed: ${error}, ${JSON.stringify(error, null, 2)}, ${message}`);
    throw error;
  }
}

export async function jsonBodyFetch<BodyArguments, Response>(
  path: string,
  method: string,
  args: BodyArguments
): Promise<Response> {
  const apiPath = `${configuration.api.url}${path}`;
  try {
    const response = await fetch(apiPath, {
      headers: { "Content-type": "application/json" },
      method,
      body: JSON.stringify(args),
    });

    if (response.status === 401) {
      throw new Error(`Unauthorized`);
    }

    if (response.status >= 400) {
      if (response.status === 400) {
        const body = await response.json();
        throw body;
      }
      throw new Error(`Response status is ${response.status}`);
    }
    return response.json();
  } catch (error: unknown) {
    let message = "Unknown error";
    if (error instanceof Error) message = error.message;
    captureMessage(`jsonBodyFetch ${method} ${path} failed: ${error}, ${JSON.stringify(error, null, 2)}, ${message}`);
    throw error;
  }
}
