import { PaymentElement } from "@stripe/react-stripe-js";
import { ConfirmationToken, StripePaymentElementOptions } from "@stripe/stripe-js";
import configuration from "../../configuration";
import { PaymentMethodPreview } from "../PaymentMethodPreview";

interface Props {
  isEditingPaymentMethod: boolean;
  confirmationToken: ConfirmationToken | undefined;
  setIsEditingPaymentMethod: React.Dispatch<React.SetStateAction<boolean>>;
  onPaymentElementReady: () => void;
  onPaymentElementLoadError: () => void;
}

export function PurchasePayment({
  isEditingPaymentMethod,
  confirmationToken,
  setIsEditingPaymentMethod,
  onPaymentElementReady,
  onPaymentElementLoadError,
}: Props) {
  const paymentElementOptions: StripePaymentElementOptions = {
    layout: {
      type: "accordion",
      defaultCollapsed: false,
      radios: true,
      spacedAccordionItems: false,
    },
    business: { name: configuration.business.name },
    paymentMethodOrder: ["paypal", "klarna", "card", "sofort"],
  };

  return (
    <>
      <div className="font-bold mt-7 mb-3">Zahlung</div>
      {confirmationToken && !isEditingPaymentMethod ? (
        <div>
          <span className="flex items-center gap-4 mb-3 justify-between">
            <span>Gewählte Zahlungsmethode</span>
            <button
              type="button"
              onClick={() => {
                setIsEditingPaymentMethod(true);
              }}
              className="text-sm text-neutral-900 underline"
            >
              Ändern
            </button>
          </span>
          <PaymentMethodPreview paymentMethodPreview={confirmationToken.payment_method_preview} />
        </div>
      ) : (
        <>
          <PaymentElement
            id="payment-element"
            options={paymentElementOptions}
            onReady={() => onPaymentElementReady()}
            onLoadError={() => onPaymentElementLoadError()}
          />
          {confirmationToken && (
            <button
              type="button"
              onClick={() => {
                setIsEditingPaymentMethod(false);
              }}
              className="text-sm text-neutral-900 underline mt-4"
            >
              Abbrechen
            </button>
          )}
        </>
      )}
    </>
  );
}
