import { useMemo } from "react";
import { camelToSnake } from "../utils/casing";

interface Props<T extends string, V extends string, S extends Record<T, V> | undefined> {
  buttons: { caption: string; value: V; extra?: string }[];
  formKey: T;
  formValue: S;
  setValue: (key: T, value: V) => void;
  className: string;
  checkedClassName?: string;
  uncheckedClassName?: string;
}

export function RadioGroup<T extends string, V extends string, S extends Record<T, V> | undefined>({
  formKey,
  buttons,
  setValue,
  formValue,
  className,
  checkedClassName = "",
  uncheckedClassName = "",
}: Props<T, V, S>) {
  const name = useMemo(() => camelToSnake(formKey), [formKey]);

  return (
    <>
      {buttons.map(({ caption, value, extra }) => (
        <label
          key={value}
          className={`flex gap-3 cursor-pointer items-center select-none ${className} ${
            formValue?.[formKey] === value ? checkedClassName : uncheckedClassName
          }`}
        >
          <input
            className="appearance-none before:content-[''] w-5 h-5 rounded-full border border-solid border-slate-900 before:w-3 before:h-3 before:bg-green grid place-content-center before:scale-0 before:checked:scale-100 before:transition-all shrink-0 before:rounded-full"
            type="radio"
            name={name}
            value={value}
            checked={formValue?.[formKey] === value}
            onChange={(event) => setValue(formKey, event.target.value as V)}
          />
          <span className="grow">{caption}</span>
          {extra && <span>{extra}</span>}
        </label>
      ))}
    </>
  );
}
